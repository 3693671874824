import React, { useEffect, useState } from 'react'
import './App.css'
import { PublicDisplay } from './components/organisms/PublicDisplay/PublicDisplay'
import { getMonday, isProduction } from './logic/util'
import { reducer, StateProvider } from './logic/state'
import { auth } from './logic/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { UserCredential, User } from 'firebase/auth'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ControlPanel } from './components/organisms/ControlPanel/ControlPanel'
import { ClickableList } from './components/molecules/ClickableList/ClickableList'
import { EmailList } from './components/organisms/EmailList/EmailList'
import { Sensorpush } from './components/organisms/Sensorpush/Sensorpush'
import { Login } from './components/organisms/Login/Login'
import { CoreTaskList } from './components/organisms/CoreTaskList/CoreTaskList'
import { PrintProductionPlan } from './components/organisms/PrintProductionPlan/PrintProductionPlan'
import { getGlobalSettings } from './services/firestore/global-settings.service'
import { WeekOverview } from './components/organisms/PublicDisplay/WeekOverview/WeekOverview'

const ONE_MINUTE_MS = 60000

const initialState = {
  monday: getMonday(new Date()),
}

const App: React.FC = () => {
  const [refreshIntervalCoreTasks, setRefreshIntervalCoreTasks] =
    useState(ONE_MINUTE_MS)
  const [error, setError] = useState<Error | null>(null)
  const [user, setUser] = useState<UserCredential | User | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const logginInParagraph = <p>Du wirst eingeloggt...</p>

  useEffect(() => {
    const retrieveRefreshIntervalCoreTasks = async () => {
      const { refreshIntervalCoreTasks } = await getGlobalSettings()
      setRefreshIntervalCoreTasks(refreshIntervalCoreTasks)
    }
    retrieveRefreshIntervalCoreTasks()
  }, [])

  const login = async () => {
    auth.onAuthStateChanged(async function (user) {
      try {
        if (user) {
          // User is signed in.
          setUser(user)
          setError(null)
        } else {
          setUser(null)
          // No user is signed in.
        }

        if (user === null) {
          const query = new URLSearchParams(window.location.search)
          let password = query.get('password')
          if (password == null && isProduction() === false) {
            password = 'wuWDjvezDVcTxXirNNYYn7aM'
          }
          if (!password) {
            throw new Error('Kein Passwort in der URL angegeben!')
          }

          const user = await signInWithEmailAndPassword(
            auth,
            'firebaseauth-backend@kinderkueche.com',
            password
          )
          setUser(user)
          setError(null)
        }
      } catch (error) {
        setUser(null)
        setError(error)
      }
    })
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    login()
  }, [])

  const stillLoggingIn = isLoading || !user
  if (error) {
    return <Login />
  } else if (stillLoggingIn) {
    return logginInParagraph
  } else {
    return (
      <StateProvider initialState={initialState} reducer={reducer}>
        <BrowserRouter>
          <Routes>
            <Route path="/home" element={<ClickableList />} />
            <Route path="/" element={<ClickableList />} />
            <Route
              path="/control-panel"
              element={
                <div className="App">
                  <div className="controlPanel">
                    <ControlPanel />
                  </div>
                </div>
              }
            />
            <Route
              path="/public-display"
              element={
                <div className="publicDisplay">
                  <PublicDisplay />
                </div>
              }
            />
            \
            <Route
              path="/week-overview"
              element={
                <div className="weekOverview">
                  <WeekOverview />
                </div>
              }
            />
            <Route
              path="/email-list"
              element={
                <div className="emailList">
                  <EmailList />
                </div>
              }
            />
            <Route
              path="/sensorpush"
              element={
                <div className="sensorpush">
                  <Sensorpush />
                </div>
              }
            />
            <Route
              path="/login"
              element={
                <div className="login">
                  <Login />
                </div>
              }
            />
            <Route
              path="/coreTasks"
              element={
                <div className="coreTasks">
                  <CoreTaskList
                    refreshIntervalCoreTasks={refreshIntervalCoreTasks}
                  />
                </div>
              }
            />
            <Route
              path="/printProductionPlan/:day"
              element={
                <div className="printProductionPlan">
                  <PrintProductionPlan />
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </StateProvider>
    )
  }
}

export default App
