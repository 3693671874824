import React, { useState } from 'react'
import { DateElement } from '../../atoms/DateElement/DateElement'
import { DragAndDrop } from '../../functionality/DragAndDrop/DragAndDrop'
import { FileComponent } from '../FileComponent/FileComponent'
import './DeliveryDay.css'
import { formatDateForCloudFunction } from '../../../logic/util'
import { firestore, cloudFunctions } from '../../../logic/firebase'
import { DeliveryDay, DeliveryDayLoadingState } from '../../../types'
import { ButtonGroup } from '../ButtonGroup/ButtonGroup'
import { httpsCallable } from 'firebase/functions'
import {
  COLLECTION_DELIVERY_DAYS,
  COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS,
} from '../../../constants'
import { collection, doc, query, where } from 'firebase/firestore'
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore'

type DeliveryDayCellProps = {
  date: Date
}

const importFile = httpsCallable(cloudFunctions, 'importFileCall')

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const DeliveryDayCell = (props: DeliveryDayCellProps) => {
  const [deleting, setDeleting] = useState<boolean>(false)
  const [uploadingFile, setUploadingFile] = useState<boolean>(false)
  const [importingEmployees, setImportingEmployees] = useState<boolean>(false)

  const dDayRef = doc(
    firestore,
    COLLECTION_DELIVERY_DAYS,
    formatDateForCloudFunction(props.date)
  )

  const [deliveryDayData, loadingDeliveryDay] = useDocumentData(dDayRef)

  const employeesQuery = query(
    collection(firestore, COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS),
    where('deliveryDayRef', '==', dDayRef)
  )
  const [employees] = useCollectionData(employeesQuery)

  const employeesImported = employees && employees.length > 0

  const handleDrop = async (files: FileList) => {
    // send data to cloud function.
    if (uploading || deleting) {
      return
    }
    try {
      setUploadingFile(true)
      const formData = new FormData()
      formData.append('file', files[0])
      formData.append('date', formatDateForCloudFunction(props.date))
      const base64File = await toBase64(files[0])
      await importFile({
        file: base64File,
        date: formatDateForCloudFunction(props.date),
        filename: files[0].name,
      })
      setUploadingFile(false)
    } catch (e) {
      console.error(e)
      setUploadingFile(false)
    }
  }

  const deleteButtonPressed = async () => {
    try {
      const date = formatDateForCloudFunction(props.date)
      const deleteDeliveryDay = httpsCallable(
        cloudFunctions,
        'deleteDeliveryDay'
      )
      setDeleting(true)
      await deleteDeliveryDay({ date: date })
      setDeleting(false)
    } catch (e) {
      console.error(e)
    }
  }

  const onDownloadButtonPressed = async (url: string) => {
    window.open(url)
  }

  const onImportEmployeesButtonPressed = async () => {
    const date = formatDateForCloudFunction(props.date)
    const importEmployeesForDeliveryDay = httpsCallable(
      cloudFunctions,
      'importEmployeesForDeliveryDay'
    )
    setImportingEmployees(true)
    await importEmployeesForDeliveryDay({ date: date })
    setImportingEmployees(false)
  }

  let data: DeliveryDayLoadingState = { state: 'not_available' }
  if (deleting) {
    data = { state: 'deleting' }
  } else if (deliveryDayData) {
    const deliveryDay: DeliveryDay = deliveryDayData as DeliveryDay

    if (deliveryDay) {
      data = { state: 'loaded', deliveryDay }
    }
  } else if (loadingDeliveryDay || uploadingFile) {
    data = { state: 'loading' }
  }

  const uploading =
    data.state === 'loaded' && data.deliveryDay.uploadState === 'uploading'

  return (
    <DragAndDrop
      handleDrop={handleDrop}
      uploading={uploading}
      deleting={deleting}
    >
      <div className="container-delivery-day">
        <DateElement date={props.date} />
        <div className="divider" />
        <FileComponent deliveryDayLoadingState={data} />
        {data.state === 'loaded' && (
          <ButtonGroup
            deliveryDay={data.deliveryDay}
            deleting={deleting}
            uploading={uploading}
            onDownloadButtonPressed={onDownloadButtonPressed}
            importingEmployees={importingEmployees}
            importEmployeesButtonPressed={onImportEmployeesButtonPressed}
            deleteButtonPressed={deleteButtonPressed}
            state={data.state}
            employeesImported={employeesImported}
          ></ButtonGroup>
        )}
      </div>
    </DragAndDrop>
  )
}
