import React from 'react'
import './Button.css'
import loading from '../../../res/loading-spinner.svg'

type ButtonProps = {
  onPress: () => void
  blocked: boolean
  image: string
  backgroundColor?: string
}
export const Button = (props: ButtonProps) => {
  let style: any = { padding: 10 }
  if (props.blocked) {
    style.animation = 'spin 1s linear infinite'
  } else {
    if (props.backgroundColor !== undefined) {
      style.backgroundColor = props.backgroundColor
    }
  }

  return (
    <div
      className="button"
      onClick={() => {
        if (props.blocked === false) {
          props.onPress()
        }
      }}
    >
      <img
        src={props.blocked ? loading : props.image}
        alt={props.blocked ? loading : props.image}
        width={24}
        height={24}
        style={style}
      />
    </div>
  )
}
