export const parseDate = (date: Date): string => {
  const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
  if (isToday(date)) {
    return 'Heute'
  } else if (isTomorrow(date)) {
    return 'Morgen'
  } else {
    const dayName = days[date.getDay()]
    // console.log(date.getDate(), date.getMonth(), date.getFullYear());
    const dayDate = date.getDate()
    // We need to add 1 to the month since January is 0 etc
    const dayMonth = date.getMonth() + 1
    return `${dayName}, ${dayDate}.${dayMonth}`
  }
}
const isToday = (date: Date) => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

const isTomorrow = (date: Date) => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  )
}

export const getMonday = (date: Date) => {
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)
  return new Date(date.setDate(diff))
}
export const weekInMs = 604800000
export type AppState = {
  monday: Date
}

export const formatDateForCloudFunction = (date: Date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}

export const DELIVERY_DAY = '/deliveryDays'

export const isProduction = () => {
  // if we use npm start (that means testing locally), we always want to use KK-Development environment
  // if we use npm build it depends on KK_ENVIRONMENT if we use production or development
  let isProduction =
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_KK_ENVIRONMENT === 'production'

  return isProduction
}

export const firebaseEmulatorsRunning =
  process.env.REACT_APP_FIREBASE_EMULATORS_RUNNING === 'true'
// console.log('Using Firebase Emulators: ', firebaseEmulatorsRunning)
