import React, { CSSProperties } from 'react'
import { spacing } from '../../../theme/spacing'
import { images, ImageTypes } from './Images'

interface ImageAssetProps {
  style?: CSSProperties
  image: ImageTypes
}

const ROOT = {
  height: spacing[7],
  width: spacing[7],
}

export const ImageAsset = (props: ImageAssetProps) => {
  const { style: styleOverride, image } = props
  return (
    <img src={images[image]} alt="icon" style={{ ...ROOT, ...styleOverride }} />
  )
}
