import React, { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Tour } from '../../functionality/CreateProductionPlanPDF/model/tour'
import {
  getAllEmployees,
  getAllGroupDeliveries,
  getAllGroupDeliveryComponent,
  getAllGroups,
  getAllKitchenEmployeeAssignments,
  getAllMealComponents,
  getAllProductionPlaces,
  getAllProductionsPlaceAssignments,
  getAllTours,
} from '../../functionality/CreateProductionPlanPDF'
import { GroupDelivery } from '../../functionality/CreateProductionPlanPDF/model/groupDelivery'
import { Group } from '../../functionality/CreateProductionPlanPDF/model/group'
import { GroupDeliveryComponent } from '../../functionality/CreateProductionPlanPDF/model/groupDeliveryComponent'
import { ProductionPlaceAssignments } from '../../functionality/CreateProductionPlanPDF/model/productionPlaceAssignments'
import { MealComponent } from '../../functionality/CreateProductionPlanPDF/model/mealComponent'
import { KitchenEmployeeAssignment } from '../../functionality/CreateProductionPlanPDF/model/employeeKitchenAssignment'
import { Employee } from '../../functionality/CreateProductionPlanPDF/model/employee'
import { ProductionPlace } from '../../functionality/CreateProductionPlanPDF/model/productionPlaces'
import {
  OptimoRouteResult,
  PdfProductionDayData,
} from '../../functionality/CreateProductionPlanPDF/types'
import {
  createProductionPlanPdf,
  getProductionPlacesPlanData,
  getTourTableMap,
} from '../../functionality/CreateProductionPlanPDF/pdfCreation'

import './PrintProductionPlan.css'
import { Button } from '@mui/material'
import { Print } from '@mui/icons-material'
import { cloudFunctions } from '../../../logic/firebase'
import { httpsCallable } from 'firebase/functions'

function getPickerTourTable(
  mealComponents: MealComponent[],
  groupDeliveryComponents: GroupDeliveryComponent[],
  tours: Tour[],
  groups: Group[],
  groupDeliveries: GroupDelivery[],
  filter: (meal: MealComponent) => boolean
) {
  const mealComponentsPicker2 = mealComponents.filter(filter)
  const groupDeliveryComponentsPicker2 = groupDeliveryComponents.filter((gdc) =>
    mealComponentsPicker2
      .map((meal) => meal.documentRef.path)
      .includes(gdc.mealComponentRef.path)
  )
  const tourTableMapPicker2 = getTourTableMap(
    groupDeliveryComponentsPicker2,
    tours,
    mealComponentsPicker2,
    groups,
    groupDeliveries
  )
  return tourTableMapPicker2
}

const optimoRoute = httpsCallable<{ day: string }, string>(
  cloudFunctions,
  'getOptimoRoute'
)

async function getOptimoRoute(day: string): Promise<OptimoRouteResult> {
  const { data } = await optimoRoute({ day })
  const result: OptimoRouteResult = JSON.parse(data)
  return result
}

export const PrintProductionPlan = () => {
  const { day } = useParams()
  const [printHtml, setPrintHtml] = useState<string>(null)

  const getHTML = async (url: string) => {
    const tours: Tour[] = await getAllTours(url)
    const groupDeliveries: GroupDelivery[] = await getAllGroupDeliveries(url)
    const groups: Group[] = await getAllGroups(groupDeliveries)
    const groupDeliveryComponents: GroupDeliveryComponent[] =
      await getAllGroupDeliveryComponent(url)
    const productionPlaceAssignments: ProductionPlaceAssignments[] =
      await getAllProductionsPlaceAssignments(url)
    const mealComponents: MealComponent[] = await getAllMealComponents(url)
    const employeeKitchenAssigment: KitchenEmployeeAssignment[] =
      await getAllKitchenEmployeeAssignments(url)

    // get all information independent of delivery day
    const employees: Employee[] = await getAllEmployees(
      employeeKitchenAssigment
    )
    let productionPlaces: ProductionPlace[] = await getAllProductionPlaces(
      productionPlaceAssignments
    )
    productionPlaces.sort((a, b) => (a.sortId > b.sortId ? 1 : -1))

    const data: PdfProductionDayData = {
      productionPlaces: [],
      deliveryDay: url,
      pickerTourTableMap: [],
      routes: null,
    }
    // data for production places
    const productionPlacesPlanData = getProductionPlacesPlanData(
      productionPlaces,
      employeeKitchenAssigment,
      employees,
      productionPlaceAssignments,
      tours,
      mealComponents,
      groupDeliveryComponents,
      groupDeliveries,
      groups
    )
    data.productionPlaces = productionPlacesPlanData

    // data for picker
    const tourTableMapPicker1 = getPickerTourTable(
      mealComponents,
      groupDeliveryComponents,
      tours,
      groups,
      groupDeliveries,
      (meal) => meal.assignPicker2 === false
    )
    const tourTableMapPicker2 = getPickerTourTable(
      mealComponents,
      groupDeliveryComponents,
      tours,
      groups,
      groupDeliveries,
      (meal) => meal.assignPicker2 === true
    )

    data.pickerTourTableMap.push(tourTableMapPicker1)
    const isPickerEmpty =
      tourTableMapPicker2.length > 0
        ? tourTableMapPicker2
            .map((ttm) => ttm.header.length === 0)
            .reduce((pre, cur) => pre && cur)
        : true
    if (isPickerEmpty === false) {
      data.pickerTourTableMap.push(tourTableMapPicker2)
    }

    const optimoRouteResult = await getOptimoRoute(day)
    if (optimoRouteResult.success) {
      data.routes = optimoRouteResult.routes
    }

    const html = await createProductionPlanPdf(data)
    setPrintHtml(html)
  }

  useLayoutEffect(() => {
    document.title = 'Kinderküche Produktionsplan ' + day
    getHTML(day)
    // eslint-disable-next-line
  }, [])

  const print = () => {
    try {
      // Print for Safari browser
      document.execCommand('print', false, null)
    } catch {
      window.print()
    }
  }

  if (printHtml == null) {
    return (
      <div>
        <p>Lade Daten ...</p>
      </div>
    )
  }

  return (
    <div className="print-div">
      <Button
        onClick={() => print()}
        style={{ width: 120, backgroundColor: '#f0f0f0' }}
        className="print-button"
      >
        <Print />
      </Button>
      <div dangerouslySetInnerHTML={{ __html: printHtml }} />
    </div>
  )
}
