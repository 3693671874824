import React from 'react'
import arrowBack from '../../../res/sidebar-arrow-left.svg'
import arrowForward from '../../../res/sidebar-arrow-right.svg'
import './WeekButton.css'

type WeekButtonProps = {
  active: boolean
  direction: 'forward' | 'back'
  onPress: () => void
}

export const WeekButton = (props: WeekButtonProps) => {
  return (
    <div className="week-button" onClick={() => props.onPress()}>
      <img
        className={props.active ? 'wb-active' : 'wb-inactive'}
        src={props.direction === 'forward' ? arrowForward : arrowBack}
        alt={arrowBack}
      />
    </div>
  )
}
