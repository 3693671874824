import { firestore } from '../../../logic/firebase'
import {
  COLLECTION_GROUP_DELIVIERIES,
  COLLECTION_GROUP_DELIVIERY_COMPONENTS,
  COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS,
  COLLECTION_MEAL_COMPONENTS,
  COLLECTION_PRODUCTION_PLACE_ASSIGNMENTS,
  COLLECTION_TOURS,
} from '../../../constants'
import { DELIVERY_DAY } from '../../../logic/util'
import { Tour, tourConverter } from './model/tour'
import { groupDeliveryConverter, GroupDelivery } from './model/groupDelivery'
import {
  GroupDeliveryComponent,
  groupDeliveryComponentConverter,
} from './model/groupDeliveryComponent'
import {
  ProductionPlaceAssignments,
  productionPlaceAssignmentsConverter,
} from './model/productionPlaceAssignments'
import { MealComponent, mealComponentConverter } from './model/mealComponent'
import {
  KitchenEmployeeAssignment,
  kitchenEmployeeAssignmentConverter,
} from './model/employeeKitchenAssignment'
import { Employee } from './model/employee'
import { ProductionPlace } from './model/productionPlaces'
import {
  collection,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { Group } from './model/group'

function deliveryDayRef(deliveryDayID: string) {
  return doc(firestore, DELIVERY_DAY, deliveryDayID)
}

export async function getDocumentsFromStore<T>(
  deliveryDay: string,
  firestoreCollection: string,
  converter: FirestoreDataConverter<T, {}>
) {
  const firestoreQuery = query(
    collection(firestore, firestoreCollection).withConverter(converter),
    where('deliveryDayRef', '==', deliveryDayRef(deliveryDay))
  )
  const documents = await getDocs(firestoreQuery)

  return documents.docs.map((value) => value.data())
}

export async function getAllTours(deliveryDay: string) {
  return getDocumentsFromStore<Tour>(
    deliveryDay,
    COLLECTION_TOURS,
    tourConverter
  )
}

export async function getAllGroupDeliveries(deliveryDay: string) {
  return getDocumentsFromStore<GroupDelivery>(
    deliveryDay,
    COLLECTION_GROUP_DELIVIERIES,
    groupDeliveryConverter
  )
}

export async function getAllGroups(groupDelivery: GroupDelivery[]) {
  const groupReferencesArr = groupDelivery.map((delivery) => delivery.groupRef)

  const uniqueRefs: DocumentReference[] = []
  const uniqueIds: string[] = []
  for (const ref of groupReferencesArr) {
    if (!uniqueIds.includes(ref.id)) {
      uniqueRefs.push(ref)
      uniqueIds.push(ref.id)
    }
  }

  const groupsSnapshots = await Promise.all(
    uniqueRefs.map((groupRef) => getDoc(groupRef))
  )
  return groupsSnapshots.map((snap) => new Group(snap))
}

export async function getAllGroupDeliveryComponent(deliveryDay: string) {
  return getDocumentsFromStore<GroupDeliveryComponent>(
    deliveryDay,
    COLLECTION_GROUP_DELIVIERY_COMPONENTS,
    groupDeliveryComponentConverter
  )
}

export async function getAllProductionsPlaceAssignments(deliveryDay: string) {
  return getDocumentsFromStore<ProductionPlaceAssignments>(
    deliveryDay,
    COLLECTION_PRODUCTION_PLACE_ASSIGNMENTS,
    productionPlaceAssignmentsConverter
  )
}

export async function getAllMealComponents(deliveryDay: string) {
  return getDocumentsFromStore<MealComponent>(
    deliveryDay,
    COLLECTION_MEAL_COMPONENTS,
    mealComponentConverter
  )
}

export async function getAllKitchenEmployeeAssignments(deliveryDay: string) {
  return getDocumentsFromStore<KitchenEmployeeAssignment>(
    deliveryDay,
    COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS,
    kitchenEmployeeAssignmentConverter
  )
}

export async function getAllEmployees(
  kitchenEmployeeAssignments: KitchenEmployeeAssignment[]
) {
  const employeeReferencesArr = kitchenEmployeeAssignments.map(
    (assignment) => assignment.employeeRef
  )
  const uniqueRefs: DocumentReference[] = []
  const uniqueIds: string[] = []
  for (const ref of employeeReferencesArr) {
    if (!uniqueIds.includes(ref.id)) {
      uniqueRefs.push(ref)
      uniqueIds.push(ref.id)
    }
  }
  const employeeSnapshots = await Promise.all(
    uniqueRefs.map((employeeRef) => getDoc(employeeRef))
  )
  return employeeSnapshots.map((snap) => new Employee(snap))
}

export async function getAllProductionPlaces(
  productionPlaceAssignments: ProductionPlaceAssignments[]
) {
  const productionPlaceArr = productionPlaceAssignments.map(
    (assignment) => assignment.productionPlaceRef
  )
  const uniqueRefs: DocumentReference[] = []
  const uniqueIds: string[] = []
  for (const ref of productionPlaceArr) {
    if (!uniqueIds.includes(ref.id)) {
      uniqueRefs.push(ref)
      uniqueIds.push(ref.id)
    }
  }
  const productionPlaceSnapshots = await Promise.all(
    uniqueRefs.map((productionPlaceRef) => getDoc(productionPlaceRef))
  )
  return productionPlaceSnapshots.map((snap) => new ProductionPlace(snap))
}

export function getEmployeesForProductionPlace(
  productionPlace: ProductionPlace,
  employeeKitchenAssigment: KitchenEmployeeAssignment[],
  employees: Employee[]
) {
  // get employees for production place
  const productionPlaceRef = productionPlace.documentRef
  // get all employees from employeeAssignment
  const employeeRefs = employeeKitchenAssigment
    .filter(
      (assigment) =>
        assigment.productionPlaceRef &&
        assigment.productionPlaceRef.path === productionPlaceRef.path
    )
    .map(($0) => $0.employeeRef.path)
  const productionPlaceEmployees = employees.filter((employee) =>
    employeeRefs.includes(employee.documentRef.path)
  )
  return productionPlaceEmployees
}
