import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore'
import { KKElement } from './kkElement'
import { COLLECTION_PRODUCTION_PLACE_ASSIGNMENTS } from '../../../../constants'

export class ProductionPlaceAssignments extends KKElement {
  documentRef: DocumentReference | undefined
  deliveryDayRef: DocumentReference
  mealComponentRef: DocumentReference
  productionPlaceRef: DocumentReference
  tourRef: DocumentReference
  totalWeight: number
  unit: string

  constructor(snap: QueryDocumentSnapshot) {
    super(COLLECTION_PRODUCTION_PLACE_ASSIGNMENTS)
    const data = snap.data()
    this.deliveryDayRef = data.deliveryDayRef
    this.tourRef = data.tourRef
    this.mealComponentRef = data.mealComponentRef
    this.productionPlaceRef = data.productionPlaceRef
    this.totalWeight = data.totalWeight
    this.unit = data.unit
    this.documentRef = snap.ref
  }

  getFlatData() {
    const flat = {
      deliveryDayRef: this.deliveryDayRef,
      tourRef: this.tourRef,
      mealComponentRef: this.mealComponentRef,
      productionPlaceRef: this.productionPlaceRef,
      totalWeight: this.totalWeight,
      unit: this.unit,
    }
    return flat
  }
}

export const productionPlaceAssignmentsConverter = {
  toFirestore: (assignments: ProductionPlaceAssignments) => ({}),
  fromFirestore: (snapshot, options) => {
    return new ProductionPlaceAssignments(snapshot)
  },
}
