import React, { useEffect, useState } from 'react'
import { Header } from '../../molecules/Header/Header'
import { CoreTask } from '../../molecules/CoreTask/CoreTask'
import './CoreTaskList.css'
import { listTodaysCoreTasks } from '../../../shared/bitrix'

interface BitrixTaskData {
  id: number
  title: string
  deadline: string | null
  closedDate: string | null
  tags: string[] | null
  responsible: BitrixTaskResponsible
}

interface BitrixTaskResponsible {
  name: string
}

interface CoreTaskListProps {
  refreshIntervalCoreTasks: number
}

export const CoreTaskList = ({
  refreshIntervalCoreTasks,
}: CoreTaskListProps) => {
  const [coreTasks, setCoreTasks] = useState<BitrixTaskData[]>([])

  useEffect(() => {
    const getTaskList = async () => {
      //  let tasks: BitrixTaskData[] = await kkShared.bitrix.listTodaysCoreTasks()
      let tasks: BitrixTaskData[] = await listTodaysCoreTasks()
      tasks.sort(
        (task1: BitrixTaskData, task2: BitrixTaskData) =>
          new Date(task1.deadline).getTime() -
          new Date(task2.deadline).getTime()
      )
      setCoreTasks(tasks)
    }
    getTaskList()
    const interval = setInterval(() => {
      getTaskList()
    }, refreshIntervalCoreTasks)

    return () => clearInterval(interval)
  })

  useEffect(() => {}, [])

  return (
    <div>
      <Header />
      <div className="coreTaskListOuterBox">
        <div className="coreTaskListHeader">Kernaufgaben</div>
        <div className="coreTaskListTasks">
          {coreTasks.map((task, index) => {
            return (
              <CoreTask
                key={index}
                {...task}
                assignee={task.responsible.name}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
