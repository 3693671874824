import React from 'react'
import { HeaderPercentageIndicators } from '../../../atoms/HeaderPercentageIndicators/HeaderPercentageIndicators'
import './StatisticBloc.css'

type StatisticBlocProps = {
  title: string
  kitchenPercentage: number | string
  pickerPercentage: number | string
  driverPercentage: number | string
  loading: boolean
}

export const StatisticBloc = (props: StatisticBlocProps) => {
  return (
    <div>
      <div className="statistic-headline">{props.title}</div>
      <div className="right-indicators-container">
        <HeaderPercentageIndicators
          iconName={'pot.png'}
          percentage={props.loading ? '- ' : props.kitchenPercentage}
        />
        <HeaderPercentageIndicators
          iconName={'cart.png'}
          percentage={props.loading ? '- ' : props.pickerPercentage}
        />
        <HeaderPercentageIndicators
          iconName={'truck.png'}
          percentage={props.loading ? '- ' : props.driverPercentage}
        />
      </div>
    </div>
  )
}
