import React, { CSSProperties } from 'react'
import './SensorpushGateway.css'
import { ImageAsset } from '../../atoms/Image/Image'
import { ImageTypes } from '../../atoms/Image/Images'

interface SensorPushGatewayProps {
  text: string
  image: ImageTypes
  imageStyles?: CSSProperties
}

export const SensorPushGateway = (props: SensorPushGatewayProps) => {
  const { text, image, imageStyles } = props
  return (
    <div className="box">
      <ImageAsset image={image} style={imageStyles} />
      <div className="header-text">{text}</div>
    </div>
  )
}
