import { DocumentReference, Firestore } from 'firebase/firestore'

export class KKElement {
  documentRef: DocumentReference | undefined
  collection: string
  constructor(collection: string) {
    this.collection = collection
  }
  getLocigalKey(): string {
    return ''
  }
  getFlatData(db: Firestore, dayRef: DocumentReference) {
    return {}
  }
}
