export const tenMinutesInSeconds: number = 600

export const COLLECTION_SENSORPUSH_GATEWAYS = 'sensorpushGateways'
export const COLLECTION_SENSORPUSH_SENSORS = 'sensorpushSensors'
export const COLLECTION_BITRIX_USER_IDS = 'bitrixUserIds'
export const COLLECTION_PLACE_MARKS = 'collectionPlaceMarks'
export const COLLECTION_PLACES = 'collectionPlaces'
export const COLLECTION_DELIVERY_DAY_STATISTICS = 'deliveryDayStatistics'
export const COLLECTION_DELIVERY_DAYS = 'deliveryDays'
export const COLLECTION_EMPLOYEES = 'employees'
export const COLLECTION_FACILITIES = 'facilities'
export const COLLECTION_GROUP_DELIVIERIES = 'groupDeliveries'
export const COLLECTION_GROUP_DELIVIERY_COMPONENTS = 'groupDeliveryComponents'
export const COLLECTION_GROUPS = 'groups'
export const COLLECTION_KITCHEN_EMPLOYEEASSIGNMENTS =
  'kitchenEmployeeAssignments'
export const COLLECTION_MEAL_COMPONENTS = 'mealComponents'
export const COLLECTION_PRODUCTION_PLACE_ASSIGNMENTS =
  'productionPlaceAssignments'
export const COLLECTION_PRODUCTION_PLACES = 'productionPlaces'
export const COLLECTION_TOURS = 'tours'
export const COLLECTION_GLOBAL_SETTINGS = 'globalSettings'

export const DEFAULT_FACILITY_ID = 'zurlKmouyxTTdroBBaV5'

export const CLOUD_FUNCTION_CREATE_DELIVERY_DAY_STATISTIC_OBJECT =
  'createDeliveryDayStatisticObject'

export const CLOUD_FUNCTION_CREATE_ANALYSIS_CSV = 'createAnalysisCSV'
