import {
  DocumentReference,
  QueryDocumentSnapshot,
  Firestore,
  doc,
} from 'firebase/firestore'
import { firestore } from '../../../../logic/firebase'
import { KKElement } from './kkElement'
import {
  COLLECTION_FACILITIES,
  COLLECTION_GROUPS,
  DEFAULT_FACILITY_ID,
} from '../../../../constants'

export class Group extends KKElement {
  documentRef: DocumentReference | undefined
  name: string
  shortName: string
  constructor(snap: QueryDocumentSnapshot) {
    super(COLLECTION_GROUPS)
    const data = snap.data()
    this.name = data.name
    this.shortName = data.shortName
    this.documentRef = snap.ref
  }

  getLogicalKey(): string {
    return this.shortName
  }
  getFlatData(db: Firestore, dayRef: DocumentReference) {
    return {
      shortName: this.shortName,
      name: this.name,
      facilityRef: doc(firestore, COLLECTION_FACILITIES, DEFAULT_FACILITY_ID),
      createdAt: new Date(),
      updatedAt: new Date(),
    }
  }
}

export const groupConverter = {
  toFirestore: (groupDelivery: Group) => ({}),
  fromFirestore: (snapshot, options) => {
    return new Group(snapshot)
  },
}
