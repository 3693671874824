import { COLLECTION_EMPLOYEES } from '../../../../constants'
import { QueryDocumentSnapshot } from 'firebase/firestore'
import { KKElement } from './kkElement'

export class Employee extends KKElement {
  firstName: string
  lastName: string
  roles: string[]
  isActive: boolean
  constructor(snap: QueryDocumentSnapshot) {
    super(COLLECTION_EMPLOYEES)
    const data = snap.data()
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.roles = data.roles
    this.isActive = data.isActive
    this.documentRef = snap.ref
  }
  getLogicalKey(): string {
    return this.firstName + this.lastName
  }
  getFlatData() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      roles: this.roles,
      isActive: this.isActive,
      createdAt: new Date(),
      updatedAt: new Date(),
    }
  }
}

export const employeeConverter = {
  toFirestore: (employee: Employee) => ({}),
  fromFirestore: (snapshot, options) => {
    return new Employee(snapshot)
  },
}
