import React, { createContext, useContext, useReducer } from 'react'
import { AppState, weekInMs } from './util'

// @ts-ignore
const StateContext = createContext()
// @ts-ignore
export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)
export const useStateValue = () => useContext(StateContext)

export const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case 'forwards':
      return { monday: new Date(state.monday.getTime() + weekInMs) }
    case 'backwards':
      return { monday: new Date(state.monday.getTime() - weekInMs) }
    default:
      return state
  }
}
