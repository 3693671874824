import React, { useState } from 'react'
import { WeekButton } from '../../atoms/WeekButton/WeekButton'
import './WeekPicker.css'
import { useStateValue } from '../../../logic/state'
import { getMonday } from '../../../logic/util'

export const WeekPicker = () => {
  // Initialize the component with a week. Identify the week by a Date that is
  // during that week.

  // TODO: only active buttons should be pressable
  // @ts-ignore
  const [{ monday }, dispatch] = useStateValue()

  const weekInMs = 604800000
  const [week, setWeek] = useState(new Date().getTime())
  const onWeekPress = (forward: boolean) => {
    //console.log('pressed');
    if (forward === true) {
      dispatch({ type: 'forwards' })
      setWeek(week + weekInMs)
    } else {
      if (!isCurrentWeek(monday)) {
        dispatch({ type: 'backwards' })
        setWeek(week - weekInMs)
      }
    }
  }

  let weekText = 'Aktuelle Woche'
  if (!isCurrentWeek(monday)) {
    const firstDate = monday as Date
    const secondDate = new Date(firstDate.getTime() + (weekInMs / 7) * 6)
    let firstDay = firstDate.getDate()
    let firstMonth = firstDate.getMonth() + 1
    let secondDay = secondDate.getDate()
    let secondMonth = secondDate.getMonth() + 1
    weekText = `${firstDay}.${firstMonth} - ${secondDay}.${secondMonth}`
  }

  return (
    <div className="picker">
      <WeekButton
        active={!isCurrentWeek(monday)}
        direction={'back'}
        onPress={() => onWeekPress(false)}
      />
      <p className="weektext">{weekText}</p>
      <WeekButton
        active={true}
        direction={'forward'}
        onPress={() => onWeekPress(true)}
      />
    </div>
  )
}

const isCurrentWeek = (selectedMonday: Date) => {
  const currentMonday = getMonday(new Date())
  // console.log(
  //   selectedMonday.getTime() < currentMonday.getTime(),
  //   selectedMonday.getTime() - currentMonday.getTime(),
  // );
  return selectedMonday.getTime() < currentMonday.getTime()
}
