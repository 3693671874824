import React, { RefObject, useEffect, useRef, useState } from 'react'

type DragAndDropProps = {
  children: JSX.Element
  handleDrop: (files: FileList) => void
  uploading: boolean
  deleting: boolean
}

export const DragAndDrop = (props: DragAndDropProps) => {
  const dropRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()
  const [dragging, setDragging] = useState(false)
  const dragCounterRef = useRef<number>(0)
  const handleDragIn = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounterRef.current++
    if (e.dataTransfer!.items && e.dataTransfer!.items.length > 0) {
      setDragging(true)
    }
  }
  const handleDragOut = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dragCounterRef.current--
    //console.log(dragCounterRef.current);
    if (dragCounterRef.current > 0) {
      return
    }
    //console.log('drag out');
    setDragging(false)
  }
  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log(dragging);
  }
  const handleDrop = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    if (e.dataTransfer!.files && e.dataTransfer!.files.length > 0) {
      props.handleDrop(e.dataTransfer!.files)
      dragCounterRef.current = 0
    }
  }
  useEffect(() => {
    if (props.uploading || props.deleting) {
      return
    }
    const div: HTMLDivElement = dropRef.current!
    div.addEventListener('dragenter', handleDragIn)
    div.addEventListener('dragleave', handleDragOut)
    div.addEventListener('dragover', handleDragOver)
    div.addEventListener('drop', handleDrop)
    return () => {
      div.removeEventListener('dragenter', handleDragIn)
      div.removeEventListener('dragleave', handleDragOut)
      div.removeEventListener('dragover', handleDragOver)
      div.removeEventListener('drop', handleDrop)
    }
  })
  return (
    <div
      ref={dropRef}
      style={{ display: 'inline-block', position: 'relative' }}
    >
      {dragging && (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
            }}
          />
        </div>
      )}
      {props.children}
    </div>
  )
}
