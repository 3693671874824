import React, { useEffect, useState } from 'react'
import { collection, documentId, query, where } from 'firebase/firestore'
import './WeekOverview.css'
import {
  HeaderMode,
  HeaderPublicDisplay,
} from '../../../molecules/HeaderPublicDisplay/HeaderPublicDisplay'
import { firestore } from '../../../../logic/firebase'
import {
  AggregatedPunctualityScores,
  DeliveryDayStatistic,
} from '../../../../statisticsObject/StatisticObjectInterface'
import { useCollection } from 'react-firebase-hooks/firestore'
import { COLLECTION_DELIVERY_DAY_STATISTICS } from '../../../../constants'
import {
  dayObjectToString,
  getAggregatedPunctualityScores,
  getDaysOfWeek,
} from '../PublicDisplayFunctions'
import { DailyIndicatorsHeader } from '../../../molecules/HeaderPublicDisplay/components/DailyIndicatorsHeader'
import { dayName } from './WeekOverviewFunctions'
import {
  AGGREGATED_STATISTICS_NO_DATA,
  deliveryDayStatisticConverter,
} from '../PublicDisplay'

export const WeekOverview = () => {
  const [currentDay, setCurrentDay] = useState<Date>(new Date())
  const [weeklyDeliveryDayStatistic, setWeeklyDeliveryDayStatistic] =
    useState<AggregatedPunctualityScores>(AGGREGATED_STATISTICS_NO_DATA)

  const tmpDate = new Date(currentDay.getTime())
  const daysOfWeek = getDaysOfWeek(tmpDate)
  const daysOfWeekForFirebase = daysOfWeek.map((day) => dayObjectToString(day))

  const [weeklyDeliveryDayStatisticsSnapshot, weeklyLoading, weeklyError] =
    useCollection<DeliveryDayStatistic>(
      query(
        collection(firestore, COLLECTION_DELIVERY_DAY_STATISTICS).withConverter(
          deliveryDayStatisticConverter
        ),
        where(documentId(), 'in', daysOfWeekForFirebase)
      )
    )

  // set weekly statistics
  useEffect(() => {
    if (!weeklyLoading && !weeklyError) {
      if (weeklyDeliveryDayStatisticsSnapshot.size > 0) {
        const result = getAggregatedPunctualityScores(
          weeklyDeliveryDayStatisticsSnapshot
        )
        setWeeklyDeliveryDayStatistic(result)
      } else {
        setWeeklyDeliveryDayStatistic(AGGREGATED_STATISTICS_NO_DATA)
      }
    }
  }, [
    currentDay,
    weeklyDeliveryDayStatisticsSnapshot,
    weeklyLoading,
    weeklyError,
  ])

  return (
    <>
      {!weeklyLoading && (
        <div>
          <HeaderPublicDisplay
            setDay={(date: Date) => setCurrentDay(date)}
            date={currentDay}
            mode={HeaderMode.WEEKS}
            punctualityScoresForDay={undefined}
            scoresForMonth={undefined}
            scoresForWeek={undefined}
            showStatistic={false}
            showEvaluation={false}
            setShowStatistic={(show) => {}}
          />
          <div className="week-overview">
            {weeklyDeliveryDayStatisticsSnapshot?.docs.map((doc, index) => (
              <div className="week-overview-statistic-container">
                <div className="weekoverview-element-title">
                  {dayName(doc.data().day, doc.data().month, doc.data().year)}
                </div>

                <DailyIndicatorsHeader
                  punctualityScoresForDay={doc.data().punctualityScoresForDay}
                  showEvaluation={true}
                />
              </div>
            ))}
            <div className="weekoverview-divider-container">
              <div className="weekoverview-divider" />
            </div>
            <div className="week-overview-statistic-container">
              <div className="weekoverview-element-title">
                Wochendurchschnitt
              </div>

              <DailyIndicatorsHeader
                punctualityScoresForDay={weeklyDeliveryDayStatistic}
                showEvaluation={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
