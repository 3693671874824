type HTMLObjectParams = { [key: string]: string }

class HTMLObject {
  type: string
  style: string | null
  children: string | null | HTMLObject[]
  params?: HTMLObjectParams

  constructor(type, style, children, params?: HTMLObjectParams) {
    this.type = type
    this.style = style
    this.children = children
    this.params = params
  }

  render() {
    let paramsString = ''
    for (const param in this.params) {
      paramsString += ` ${param}="${this.params[param]}"`
    }
    // console.log(paramsString);
    if (this.children != null && typeof this.children !== 'string') {
      let childRender = ''
      for (const child of this.children) {
        childRender += child.render()
      }
      if (this.style == null) {
        return `<${this.type}${paramsString}>${childRender}</${this.type}>`
      }
      return `<${this.type}${paramsString} style="${this.style}">${childRender}</${this.type}>`
    } else {
      if (this.style == null) {
        return `<${this.type}${paramsString}>${this.children}</${this.type}>`
      }
      return `<${this.type}${paramsString} style="${this.style}">${this.children}</${this.type}>`
    }
  }

  insertChild(newChild) {
    if (typeof this.children === 'string') {
      return
    }
    // @ts-ignore
    this.children.push(newChild)
  }
}

export default HTMLObject
