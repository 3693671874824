import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore'
import { KKElement } from './kkElement'
import { COLLECTION_MEAL_COMPONENTS } from '../../../../constants'

export class MealComponent extends KKElement {
  documentRef: DocumentReference | undefined
  deliveryDayRef: DocumentReference
  totalWeight: number
  unit: string
  name: string
  sortId: number
  isPartial: boolean
  assignPicker2: boolean

  constructor(snap: QueryDocumentSnapshot) {
    super(COLLECTION_MEAL_COMPONENTS)
    const data = snap.data()
    this.totalWeight = data.totalWeight
    this.deliveryDayRef = data.deliveryDayRef
    this.unit = data.unit
    this.name = data.name
    this.sortId = data.sortId
    this.isPartial = data && data.isPartial ? data.isPartial : false
    this.assignPicker2 = data && data.assignPicker2 ? data.assignPicker2 : false
    this.documentRef = snap.ref
  }

  getLogicalKey(): string {
    return this.name + this.unit
  }
  getFlatData() {
    return {
      name: this.name,
      deliveryDayRef: this.deliveryDayRef,
      totalWeight: this.totalWeight,
      unit: this.unit,
      sortId: this.sortId,
      createdAt: new Date(),
      updatedAt: new Date(),
    }
  }
}

export const mealComponentConverter = {
  toFirestore: (groupDelivery: MealComponent) => ({}),
  fromFirestore: (snapshot, options) => {
    return new MealComponent(snapshot)
  },
}
