import React, { useEffect, useState } from 'react'
import './Sensorpush.css'
import { Header } from '../../molecules/Header/Header'
import {
  SensorpushBlock,
  SensorpushBlockProps,
} from '../../molecules/SensorpushBlock/SensorpushBlock'
import {
  FirebaseGateway,
  FirebaseSensor,
} from '../../../SensorpushFirebaseTypes'
import { useCollection } from 'react-firebase-hooks/firestore'
import { firestore } from '../../../logic/firebase'
import {
  COLLECTION_SENSORPUSH_GATEWAYS,
  COLLECTION_SENSORPUSH_SENSORS,
} from '../../../constants'
import { assignSensorDataToGateways } from './SensorpushFunctions'
import { collection, query, where } from 'firebase/firestore'

const sensorPushGatewayConverter = {
  toFirestore: (gateway: FirebaseGateway) => ({}),
  fromFirestore: (snapshot, options) => {
    return snapshot.data(options) as FirebaseGateway
  },
}

const sensorPushSensorConverter = {
  toFirestore: (deliveryDayStatistic: FirebaseSensor) => ({}),
  fromFirestore: (snapshot, options) => {
    return snapshot.data(options) as FirebaseSensor
  },
}

export const Sensorpush = () => {
  const gatewayForSensorsWithoutGateway = 'Sensoren ohne Gateway'
  const [sensorpushGatewaysAndSensorData, setsensorpushGatewaysAndSensorData] =
    useState<SensorpushBlockProps[]>([])

  const [gatewaysSnapshot, gatewaysLoading, gatewaysError] =
    useCollection<FirebaseGateway>(
      query(
        collection(firestore, COLLECTION_SENSORPUSH_GATEWAYS).withConverter(
          sensorPushGatewayConverter
        ),
        where('paired', '==', true)
      )
    )

  const [sensorsSnapshot, sensorsLoading, sensorsError] =
    useCollection<FirebaseSensor>(
      query(
        collection(firestore, COLLECTION_SENSORPUSH_SENSORS).withConverter(
          sensorPushSensorConverter
        )
      )
    )

  useEffect(() => {
    if (
      !gatewaysLoading &&
      !gatewaysError &&
      !sensorsLoading &&
      !sensorsError
    ) {
      const data = assignSensorDataToGateways(
        gatewaysSnapshot.docs,
        sensorsSnapshot.docs
      )
      setsensorpushGatewaysAndSensorData(data)
    }
  }, [
    gatewaysLoading,
    gatewaysError,
    gatewaysSnapshot,
    sensorsLoading,
    sensorsError,
    sensorsSnapshot,
  ])

  return (
    <div>
      <Header />
      <div>
        {sensorpushGatewaysAndSensorData.map((gatewayBlock, index) => {
          if (
            gatewayBlock.gatewayText === gatewayForSensorsWithoutGateway &&
            gatewayBlock.sensors.length === 0
          ) {
            return null
          }
          return <SensorpushBlock key={index} {...gatewayBlock} />
        })}
      </div>
    </div>
  )
}
