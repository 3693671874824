import { initializeApp } from 'firebase/app'
import { isProduction, firebaseEmulatorsRunning } from './util'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'

let firebaseConfig

if (isProduction()) {
  firebaseConfig = {
    apiKey: 'AIzaSyDk4SMG1n3QVULjgwOzuWu3TWo35H8zpPo',
    authDomain: 'kinderkueche-d9d8c.firebaseapp.com',
    databaseURL: 'https://kinderkueche-d9d8c.firebaseio.com',
    projectId: 'kinderkueche-d9d8c',
    storageBucket: 'kinderkueche-d9d8c.appspot.com',
    messagingSenderId: '452832629080',
    appId: '1:452832629080:web:bd3e70e3a8582113a2152d',
    measurementId: 'G-D02997VQQT',
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyCNluZUytkaOeoWK30T4FhXNwKbc2USodk',
    authDomain: 'kinderkueche-development.firebaseapp.com',
    databaseURL: 'https://kinderkueche-development.firebaseio.com',
    projectId: 'kinderkueche-development',
    storageBucket: 'kinderkueche-development.appspot.com',
    messagingSenderId: '362384254136',
    appId: '1:362384254136:web:c7a0c37e5e39ecd6a7804d',
  }
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)
export const auth = getAuth(app)
export const cloudFunctions = getFunctions(app, 'europe-west2')

// if (firebaseEmulatorsRunning) {
//   cloudFunctions.useEmulator('localhost', 5001)
//   firestore.useEmulator('localhost', 8080)
//   const createProductionAndCollectionPlaces = cloudFunctions.httpsCallable(
//     'createProductionAndCollectionPlaces'
//   )
//   createProductionAndCollectionPlaces()
//   const createGlobalSettings = cloudFunctions.httpsCallable(
//     'createGlobalSettings'
//   )
//   createGlobalSettings()
// }
