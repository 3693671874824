import React, { useState } from 'react'
import './ClickableList.css'
import { Header } from '../Header/Header'
import ControlCameraRoundedIcon from '@mui/icons-material/ControlCameraRounded'
import VideoLabelRoundedIcon from '@mui/icons-material/VideoLabelRounded'
import GroupIcon from '@mui/icons-material/GroupRounded'
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded'
import GridRoundedIcon from '@mui/icons-material/GridOnRounded'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { ClickableCard } from '../../atoms/ClickableCard/clickable-card'
import { MultiClickableCard } from '../../atoms/MultiClickableCard/multi-clickable-card'
import sensorPushLogoBlack from '../../../assets/sensorPushLogo-black.png'
import sensorPushLogoWhite from '../../../assets/sensorPushLogo-white.png'
import { cloudFunctions } from '../../../logic/firebase'
import { CLOUD_FUNCTION_CREATE_ANALYSIS_CSV } from '../../../constants'
import { httpsCallable } from 'firebase/functions'

type ClickableListProps = {}

const sensorPushLogoWhiteJSXElement = (
  <img
    src={sensorPushLogoWhite}
    alt="sensorPush-logo-icon"
    style={{ height: 80, paddingBottom: 20 }}
  />
)

const sensorPushLogoBlackJSXElement = (
  <img
    src={sensorPushLogoBlack}
    alt="sensorPush-logo-icon"
    style={{ height: 80, paddingBottom: 20 }}
  />
)

async function createExport() {
  const callable = httpsCallable<unknown, { csvContent: string }>(
    cloudFunctions,
    CLOUD_FUNCTION_CREATE_ANALYSIS_CSV
  )
  const response = await callable()

  const element = document.createElement('a')
  const { csvContent } = response.data
  const file = new Blob([csvContent], {
    type: 'text/plain',
  })
  element.href = URL.createObjectURL(file)
  element.download = 'Auswertung.csv'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const ClickableList = (props: ClickableListProps) => {
  const navigate = useNavigate()

  const [creatingCSVExport, setCreatingCSVExport] = useState(false)
  const [sensorPushIcon, setSensorPushIcon] = useState(
    sensorPushLogoBlackJSXElement
  )
  return (
    <div>
      <Header showLogoutButton={true} />
      <div className="clickableListContent">
        <Typography className="buttonBlockHeader" align="center" variant="h5">
          {'Administration'}
        </Typography>
        <div className="buttonBlock">
          <ClickableCard
            text="Produktionsplanung"
            icon={<ControlCameraRoundedIcon style={{ fontSize: 100 }} />}
            onPress={() => navigate('/control-panel')}
          />
          <ClickableCard
            text="Mitarbeiterliste"
            icon={<GroupIcon style={{ fontSize: 100 }} />}
            onPress={() => navigate('/email-list')}
          />
        </div>
        <Typography className="buttonBlockHeader" align="center" variant="h5">
          {'Auswertungen'}
        </Typography>
        <div className="buttonBlock">
          <MultiClickableCard
            title="Public Display"
            buttons={[
              {
                text: 'Live Ansicht',
                onPress: () => navigate('/public-display'),
              },
              {
                text: 'Simple Auswertung',
                onPress: () => navigate('/public-display?showEvaluation=true'),
              },
              {
                text: 'Detaillierte Auswertung',
                onPress: () =>
                  navigate(
                    '/public-display?showEvaluation=true&extendedHeader=true'
                  ),
              },
              {
                text: 'Wochenauswertung',
                onPress: () => navigate('/week-overview'),
              },
            ]}
            icon={<VideoLabelRoundedIcon style={{ fontSize: 100 }} />}
          />
          <ClickableCard
            text="Sensor Push"
            icon={sensorPushIcon}
            onMouseOver={() => setSensorPushIcon(sensorPushLogoWhiteJSXElement)}
            onMouseOut={() => setSensorPushIcon(sensorPushLogoBlackJSXElement)}
            onPress={() => navigate('/sensorpush')}
          />
          <ClickableCard
            text="Kernaufgaben"
            icon={<PlaylistAddCheckRoundedIcon style={{ fontSize: 100 }} />}
            onPress={() => navigate('/coreTasks')}
          />
          <ClickableCard
            text={creatingCSVExport ? 'wird erstellt...' : 'CSV Export'}
            blocked={creatingCSVExport}
            icon={<GridRoundedIcon style={{ fontSize: 100 }} />}
            onPress={async () => {
              setCreatingCSVExport(true)
              await createExport()
              setCreatingCSVExport(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}
