import React, { useEffect, useState } from 'react'
import {
  DocumentData,
  DocumentReference,
  collection,
  doc,
  documentId,
  query,
  where,
} from 'firebase/firestore'
import './PublicDisplay.css'
import { PublicDisplayTourCell } from '../../molecules/PublicDisplayTourCell/PublicDisplayTourCell'
import {
  HeaderMode,
  HeaderPublicDisplay,
} from '../../molecules/HeaderPublicDisplay/HeaderPublicDisplay'
import { cloudFunctions, firestore } from '../../../logic/firebase'
import {
  AggregatedPunctualityScores,
  DeliveryDayStatistic,
} from '../../../statisticsObject/StatisticObjectInterface'
import { useCollection } from 'react-firebase-hooks/firestore'
import {
  CLOUD_FUNCTION_CREATE_DELIVERY_DAY_STATISTIC_OBJECT,
  COLLECTION_DELIVERY_DAYS,
  COLLECTION_DELIVERY_DAY_STATISTICS,
} from '../../../constants'
import {
  getAggregatedPunctualityScores,
  getDaysOfWeek,
  isToday,
  dayObjectToString,
} from './PublicDisplayFunctions'
import { httpsCallable } from 'firebase/functions'

async function createDeliveryDayStatisticObject(day: string): Promise<void> {
  const createDeliveryDayStatisticObject = httpsCallable(
    cloudFunctions,
    CLOUD_FUNCTION_CREATE_DELIVERY_DAY_STATISTIC_OBJECT
  )
  await createDeliveryDayStatisticObject({
    day,
  })
  return
}

export const AGGREGATED_STATISTICS_NO_DATA = {
  kitchens: 0,
  pickers: 0,
  drivers: 0,
  loading: true,
}

export const deliveryDayStatisticConverter = {
  toFirestore: (deliveryDayStatistic: DeliveryDayStatistic) => ({}),
  fromFirestore: (snapshot, options) => {
    return snapshot.data(options) as DeliveryDayStatistic
  },
}

export const PublicDisplay = () => {
  const [currentDay, setCurrentDay] = useState(new Date())

  const [showStatistics, setShowStatistics] = useState<boolean>(false)
  const [showEvaluation, setShowEvaluation] = useState(false)

  const [monthlyDeliveryDayStatistic, setMonthlyDeliveryDayStatistic] =
    useState<AggregatedPunctualityScores>(AGGREGATED_STATISTICS_NO_DATA)
  const [weeklyDeliveryDayStatistic, setWeeklyDeliveryDayStatistic] =
    useState<AggregatedPunctualityScores>(AGGREGATED_STATISTICS_NO_DATA)
  const deliveryDayId = currentDay.toISOString().slice(0, 10)
  const dayRef: DocumentReference<DocumentData> = doc(
    firestore,
    COLLECTION_DELIVERY_DAYS,
    deliveryDayId
  )
  const [deliveryDayStatistic, setDeliveryDayStatistic] = useState<
    undefined | DeliveryDayStatistic
  >(undefined)

  const [deliveryDayStatisticSnapshot, loading, error] =
    useCollection<DeliveryDayStatistic>(
      query(
        collection(firestore, COLLECTION_DELIVERY_DAY_STATISTICS).withConverter(
          deliveryDayStatisticConverter
        ),
        where('deliveryDayRef', '==', dayRef)
      )
    )

  const [monthlyDeliveryDayStatisticsSnapshot, monthlyLoading, monthlyError] =
    useCollection<DeliveryDayStatistic>(
      query(
        collection(firestore, COLLECTION_DELIVERY_DAY_STATISTICS).withConverter(
          deliveryDayStatisticConverter
        ),
        where('month', '==', currentDay.getMonth() + 1),
        where('year', '==', currentDay.getFullYear())
      )
    )

  const evaluateUrlParameters = async () => {
    const query = new URLSearchParams(window.location.search)
    const showEvaluationUrlParam = query.get('showEvaluation')
    const extendedHeaderUrlParam = query.get('extendedHeader')
    if (extendedHeaderUrlParam === 'true') {
      setShowStatistics(true)
    }
    if (showEvaluationUrlParam === 'true') {
      setShowEvaluation(true)
    }
    if (showEvaluationUrlParam === 'false') {
      setShowEvaluation(false)
      setShowStatistics(false)
    }
  }

  useEffect(() => {
    evaluateUrlParameters()
  }, [])

  // set monthly statistics
  useEffect(() => {
    if (!monthlyLoading && !monthlyError) {
      if (monthlyDeliveryDayStatisticsSnapshot.size > 0) {
        const result = getAggregatedPunctualityScores(
          monthlyDeliveryDayStatisticsSnapshot
        )

        setMonthlyDeliveryDayStatistic(result)
      } else {
        setMonthlyDeliveryDayStatistic(AGGREGATED_STATISTICS_NO_DATA)
      }
    }
  }, [
    currentDay,
    monthlyDeliveryDayStatisticsSnapshot,
    monthlyLoading,
    monthlyError,
  ])

  const tmpDate = new Date(currentDay.getTime())
  const daysOfWeek = getDaysOfWeek(tmpDate)
  const daysOfWeekForFirebase = daysOfWeek.map((day) => dayObjectToString(day))

  const [weeklyDeliveryDayStatisticsSnapshot, weeklyLoading, weeklyError] =
    useCollection<DeliveryDayStatistic>(
      query(
        collection(firestore, COLLECTION_DELIVERY_DAY_STATISTICS).withConverter(
          deliveryDayStatisticConverter
        ),
        where(documentId(), 'in', daysOfWeekForFirebase)
      )
    )

  // set weekly statistics
  useEffect(() => {
    if (!weeklyLoading && !weeklyError) {
      if (weeklyDeliveryDayStatisticsSnapshot.size > 0) {
        const result = getAggregatedPunctualityScores(
          weeklyDeliveryDayStatisticsSnapshot
        )
        setWeeklyDeliveryDayStatistic(result)
      } else {
        setWeeklyDeliveryDayStatistic(AGGREGATED_STATISTICS_NO_DATA)
      }
    }
  }, [
    currentDay,
    weeklyDeliveryDayStatisticsSnapshot,
    weeklyLoading,
    weeklyError,
  ])
  useEffect(() => {
    async function checkDeliveryDayStatisticObject() {
      if (!loading && error === undefined) {
        if (deliveryDayStatisticSnapshot.size <= 0) {
          setDeliveryDayStatistic(undefined)
          createDeliveryDayStatisticObject(deliveryDayId)
        } else {
          setDeliveryDayStatistic(deliveryDayStatisticSnapshot.docs[0].data())
        }
      }
    }
    checkDeliveryDayStatisticObject()
  }, [loading, deliveryDayStatisticSnapshot, error, deliveryDayId])

  return (
    <>
      {!loading && (
        <div>
          <HeaderPublicDisplay
            setDay={(date: Date) => setCurrentDay(date)}
            date={currentDay}
            mode={HeaderMode.DAYS}
            punctualityScoresForDay={
              deliveryDayStatistic === undefined
                ? undefined
                : deliveryDayStatistic.punctualityScoresForDay
            }
            scoresForMonth={monthlyDeliveryDayStatistic}
            scoresForWeek={weeklyDeliveryDayStatistic}
            showStatistic={showStatistics}
            showEvaluation={showEvaluation}
            setShowStatistic={setShowStatistics}
          />
          {deliveryDayStatistic !== undefined && (
            <div className="public-display">
              <div className="grid-container">
                {deliveryDayStatistic.tours.map((tour, index) => (
                  <PublicDisplayTourCell
                    key={index}
                    tour={tour}
                    isToday={isToday(currentDay)}
                    showEvaluation={showEvaluation}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
