import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'

type MultiClickableCardProps = {
  title: string
  buttons: MultiClickableCardButtonProps[]
  icon: JSX.Element
}

type MultiClickableCardButtonProps = {
  text: string
  onPress: () => void
}

export const MultiClickableCard = (props: MultiClickableCardProps) => {
  return (
    <Card className="card" style={{ borderRadius: '10%', height: 370 }}>
      <Box
        display="flex"
        justifyContent="center"
        mt={6}
        style={{ marginTop: 15, marginBottom: 5 }}
      >
        {props.icon}
      </Box>
      <CardContent>
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography
            align="center"
            gutterBottom
            variant="h5"
            component="h2"
            style={{ marginTop: -10, marginBottom: 10 }}
          >
            {props.title}
          </Typography>
          {props.buttons.map((buttonProps, index) => {
            return (
              <Button
                key={index}
                onClick={buttonProps.onPress}
                variant="contained"
                sx={{
                  backgroundColor: 'rgb(255, 255, 255)',
                  borderRadius: 15,
                  '&:hover': {
                    backgroundColor: 'rgb(148, 187, 87)',
                    color: 'white',
                  },
                  color: 'black',
                  marginTop: index === 0 ? '10px' : 0,
                  marginBottom: index === props.buttons.length - 1 ? 0 : '10px',
                }}
              >
                {buttonProps.text}
              </Button>
            )
          })}
        </Box>
      </CardContent>
    </Card>
  )
}
