import React from 'react'
import { DateTime } from 'luxon'
import './CoreTask.css'
import { getIconForTag, firstName } from './CoreTaskFunctions'

export interface CoreTaskProps {
  title: string
  tags: string[]
  assignee: string
  deadline: string | null
  closedDate: string | null
}

export const CoreTask = (props: CoreTaskProps) => {
  const { title, tags, assignee, deadline, closedDate } = props

  const taskDeadline = DateTime.fromISO(deadline)
  const now = DateTime.now()

  let dateStyle = 'coreTaskDate'
  if (closedDate != null) {
    dateStyle += ' coreTaskDate-green'
  } else if (taskDeadline.diff(now).milliseconds < 0) {
    dateStyle += ' coreTaskDate-red'
  }

  return (
    <div className="coreTaskBox">
      <div className="innerBox">
        {tags.map((tag, index) => (
          <img
            className="coreTaskImage"
            key={index}
            src={getIconForTag(tag)}
            alt={tag}
          />
        ))}
        <div className="coreTaskMetaData">
          <div className="coreTaskMetaDataTop">
            <div className={dateStyle}>{taskDeadline.toFormat('HH:mm')}</div>
            <div className="coreTaskAssignee">{firstName(assignee)}</div>
          </div>
          <div style={{ flex: 1 }}></div>
          {tags.map((tag, index) => (
            <div key={index + 'tag'} className="coreTaskTag">
              {tag}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="coreTaskTitle">{title}</div>
      </div>
    </div>
  )
}
