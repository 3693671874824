import React, { useEffect, useState } from 'react'
import './EmailTable.css'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'
import { firestore } from '../../../logic/firebase'
import { DataGridDialog } from '../../atoms/DataGridDialog/DataGridDialog'
import { DialogType } from '../../../types'
import { Checkbox } from '@mui/material'
import { getFireStoreData, updateIsSupervisorValue } from './EmailTable.utils'
import { COLLECTION_EMPLOYEES } from '../../../constants'
import { doc, updateDoc } from 'firebase/firestore'

export const EmailTable = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [openEditEmployee, setOpenEditEmployee] = useState(false)
  const [formDataEditEmployee, setFormDataEditEmployee] = useState<{
    id: number
    email: string
    bitrixId: number
    firstName: string
    lastName: string
    isActive: string
    isSupervisor: boolean
    roles: string
    ref: any
  }>(createData(-1, '', -1, '', '', 'Inaktiv', false, '', ''))
  const [formDataNewEmployee, setFormDataNewEmployee] = useState<{
    id: number
    email: string
    bitrixId: number
    firstName: string
    lastName: string
    isActive: string
    roles: string
  }>(createData(-1, '', -1, '', '', 'Inaktiv', false, ''))
  const [rows, setRows] = useState<GridRowsProp[]>([])

  useEffect(() => {
    getFireStoreData(setRows, createData)
  }, [openEditEmployee])

  const handleClickOpenEditEmployee = (data) => {
    setFormDataEditEmployee(
      createData(
        data.row.id,
        data.row.email,
        data.row.bitrixId,
        data.row.firstName,
        data.row.lastName,
        data.row.isActive,
        data.row.isSupervisor,
        data.row.roles,
        data.row.ref.split('/')[1]
      )
    )
    setOpenEditEmployee(true)
  }

  const handleCloseEditEmployee = () => {
    setOpenEditEmployee(false)
  }

  const handleOnSave = async (type: DialogType) => {
    setIsLoading(true)
    const email =
      formDataEditEmployee.email === undefined ? '' : formDataEditEmployee.email
    const bitrixId = Number.isNaN(formDataEditEmployee.bitrixId)
      ? -1
      : formDataEditEmployee.bitrixId
    switch (type) {
      case DialogType.Edit:
        const ref = doc(
          firestore,
          COLLECTION_EMPLOYEES,
          formDataEditEmployee.ref
        )
        await updateDoc(ref, {
          firstName: formDataEditEmployee.firstName,
          lastName: formDataEditEmployee.lastName,
          email: email,
          id: bitrixId,
        })
        disableLoadingAndAllDialogs()
        break
    }
  }

  const disableLoadingAndAllDialogs = () => {
    setIsLoading(false)
    setOpenEditEmployee(false)
  }

  function createData(
    id: number,
    email: string,
    bitrixId: number,
    firstName: string,
    lastName: string,
    isActive: string,
    isSupervisor: boolean | undefined,
    roles: string,
    ref?: any
  ) {
    return {
      id,
      email,
      bitrixId,
      firstName,
      lastName,
      isActive,
      isSupervisor,
      roles,
      ref: ref,
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'bitrixId',
      headerName: 'Bitrix ID',
      width: 130,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    { field: 'firstName', headerName: 'Vorname', width: 150 },
    { field: 'lastName', headerName: 'Nachname', width: 150 },
    { field: 'email', headerName: 'E-Mail', width: 300 },
    { field: 'roles', headerName: 'Rollen', width: 200 },
    {
      field: 'isActive',
      headerName: 'Aktiv',
      width: 130,
    },
    {
      field: 'isSupervisor',
      headerName: 'Supervisor',
      width: 150,
      renderCell: (params) => <Checkbox checked={params.value === true} />,
    },
  ]

  return (
    <div className="table">
      {/* Table with data */}
      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={(data) => handleClickOpenEditEmployee(data)}
        onCellClick={(params, event) => {
          if (params.field === 'isSupervisor')
            updateIsSupervisorValue(params, event, rows, setRows, createData)
        }}
      />
      {/* Dialog to edit Employee */}
      <DataGridDialog
        open={openEditEmployee}
        handleClose={handleCloseEditEmployee}
        title={`Mitarbeiterdaten für ${formDataEditEmployee.firstName} ${formDataEditEmployee.lastName} bearbeiten`}
        description={
          'Zum Bearbeiten einfach die E-Mail oder Bitrix-Id anpassen und auf Speichern klicken.'
        }
        onChangeBitrixIdField={(data) =>
          setFormDataEditEmployee({
            id: formDataEditEmployee.id,
            email: formDataEditEmployee.email,
            bitrixId: parseInt(data.target.value),
            firstName: formDataEditEmployee.firstName,
            lastName: formDataEditEmployee.lastName,
            isActive: formDataEditEmployee.isActive,
            isSupervisor: formDataEditEmployee.isSupervisor,
            roles: formDataEditEmployee.roles,
            ref: formDataEditEmployee.ref,
          })
        }
        defaultBitrixIdFieldValue={formDataEditEmployee.bitrixId}
        onChangeEmailField={(data) =>
          setFormDataEditEmployee({
            id: formDataEditEmployee.id,
            email: data.target.value,
            bitrixId: formDataEditEmployee.bitrixId,
            firstName: formDataEditEmployee.firstName,
            lastName: formDataEditEmployee.lastName,
            isActive: formDataEditEmployee.isActive,
            isSupervisor: formDataEditEmployee.isSupervisor,
            roles: formDataEditEmployee.roles,
            ref: formDataEditEmployee.ref,
          })
        }
        defaultEmailFieldValue={formDataEditEmployee.email}
        // onDeleteDialog={handleDeleteEditEmployee}
        onCancelDialog={handleCloseEditEmployee}
        onSaveDialog={() => handleOnSave(DialogType.Edit)}
        isLoading={isLoading}
      />
    </div>
  )
}
