import React from 'react'
import './SensorpushBlock.css'
import { SensorPushGateway } from '../../molecules/SensorpushGateway/SensorpushGateway'
import {
  SensorpushSensor,
  SensorpushSensorProps,
} from '../../molecules/SensorpushSensor/SensorpushSensor'
import { ImageTypes } from '../../atoms/Image/Images'

export interface SensorpushBlockProps {
  gatewayText: string
  gatewayImage: ImageTypes
  sensors: SensorpushSensorProps[]
}

export const SensorpushBlock = (props: SensorpushBlockProps) => {
  const { gatewayText, gatewayImage, sensors } = props
  return (
    <div className="outer-box">
      <div>
        <SensorPushGateway
          text={gatewayText}
          image={
            gatewayText === 'Sensoren ohne Gateway'
              ? 'greyGateway'
              : gatewayImage
          }
          imageStyles={{
            width: '4vh',
            height: '4vh',
          }}
        />
      </div>
      <div className="sensors">
        {sensors.map((sensor, index) => {
          return <SensorpushSensor key={index} {...sensor} />
        })}
      </div>
    </div>
  )
}
