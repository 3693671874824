import React from 'react'
import { BoldTextOrDate } from '../../../atoms/Clock/BoldTextOrDate'
import {
  getGermanDateString,
  getWeekNumber,
} from '../HeaderPublicDisplayFunctions'
import ArrowDown from '../../../../assets/arrowDown.png'
import { ViewTypePublicDisplay } from '../HeaderPublicDisplay'

type ClockWithArrowsProps = {
  setDay: (date: Date) => void
  date: Date
  viewTypePublicDisplay: ViewTypePublicDisplay
}

const addToDate = (date: Date, days: number, months: number, years: number) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000
  return new Date(
    date.getTime() +
      days * oneDayInMilliseconds +
      months * 31 * oneDayInMilliseconds +
      years * 31 * 12 * oneDayInMilliseconds
  )
}

const timeIntervalForViewType = (viewType: ViewTypePublicDisplay): number => {
  if (viewType === ViewTypePublicDisplay.WeekView) {
    return 7
  } else {
    return 1
  }
}

const textForViewType = (
  viewType: ViewTypePublicDisplay,
  date: Date
): string => {
  if (viewType === ViewTypePublicDisplay.WeekView) {
    return `KW ${getWeekNumber(date)}`
  } else {
    return getGermanDateString(date)
  }
}

export const ClockWithArrows = (props: ClockWithArrowsProps) => {
  return (
    <div className="switch">
      {!(props.viewTypePublicDisplay === ViewTypePublicDisplay.LiveView) && (
        <img
          src={ArrowDown}
          width={20}
          height={20}
          style={{ transform: 'rotate(90deg)' }}
          onClick={() => {
            props.setDay(
              addToDate(
                props.date,
                -timeIntervalForViewType(props.viewTypePublicDisplay),
                0,
                0
              )
            )
          }}
          alt="arrow"
        />
      )}

      <BoldTextOrDate
        text={textForViewType(props.viewTypePublicDisplay, props.date)}
        date={props.date}
        showClockOnLiveView={
          props.viewTypePublicDisplay === ViewTypePublicDisplay.LiveView
        }
        outerStyle={{ marginLeft: 30, marginRight: 30 }}
      />
      {!(props.viewTypePublicDisplay === ViewTypePublicDisplay.LiveView) && (
        <img
          src={ArrowDown}
          width={20}
          height={20}
          style={{ transform: 'rotate(-90deg)' }}
          onClick={() => {
            props.setDay(
              addToDate(
                props.date,
                timeIntervalForViewType(props.viewTypePublicDisplay),
                0,
                0
              )
            )
          }}
          alt="arrow"
        />
      )}
    </div>
  )
}
