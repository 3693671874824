import React from 'react'
import { Button } from '../../atoms/Button/Button'
import { DeleteButton } from '../../atoms/DeleteButton/DeleteButton'
import pdfIcon from '../../../res/pdf.svg'
import gastromaticIcon from '../../../res/gastromatic.svg'
import gastromaticActiveIcon from '../../../res/gastromaticActive.svg'
import excelIcon from '../../../res/excel.svg'
import { DeliveryDay } from '../../../types'
import { Link } from 'react-router-dom'

import './ButtonGroup.css'
type ButtonGroupProps = {
  deliveryDay: DeliveryDay
  onDownloadButtonPressed: (url: string) => Promise<void>
  deleteButtonPressed: () => Promise<void>
  importEmployeesButtonPressed: () => Promise<void>
  state: string
  deleting: boolean
  uploading: boolean
  importingEmployees: boolean
  employeesImported: boolean
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  return (
    <div className="button-group">
      {props.state === 'loaded' &&
        props.deliveryDay.productionPlanImportSuccessful && (
          <Button
            onPress={() => {
              props.importEmployeesButtonPressed()
            }}
            blocked={props.importingEmployees || props.uploading}
            image={
              props.employeesImported ? gastromaticActiveIcon : gastromaticIcon
            }
            backgroundColor={props.employeesImported ? '#31AF94' : undefined}
          />
        )}
      {props.state === 'loaded' && props.deliveryDay.xlsxUrl != null && (
        <Button
          onPress={() => {
            props.onDownloadButtonPressed(props.deliveryDay.xlsxUrl)
          }}
          blocked={props.deleting || props.uploading}
          image={excelIcon}
        />
      )}
      {props.state === 'loaded' &&
        props.deliveryDay.productionPlanImportSuccessful && (
          <Link to={`/printProductionPlan/${props.deliveryDay.date}`}>
            <Button onPress={() => {}} blocked={false} image={pdfIcon} />
          </Link>
        )}
      {props.state === 'loaded' && (
        <DeleteButton
          onPress={() => {
            props.deleteButtonPressed()
          }}
          blocked={props.deleting || props.uploading}
        />
      )}
    </div>
  )
}
