import React from 'react'
import './EmailList.css'
import { Header } from '../../molecules/Header/Header'
import { EmailTable } from '../../molecules/EmailTable/EmailTable'

export const EmailList = () => {
  return (
    <div>
      <Header />
      <div className="email-list">
        <EmailTable />
      </div>
    </div>
  )
}
