import { DocumentReference } from 'firebase/firestore'

export interface DeliveryDayStatistic {
  day: number
  month: number
  year: number
  deliveryDayRef: DocumentReference
  punctualityScoresForDay: PunctualityScoresForDay
  tours: Tour[]
}

export interface Tour {
  tourNumber: number
  tourTime: number
  groupDeliveriesForTourLength: number
  kitchensSummaryForTour: KitchensSummaryForTour
  kitchenInfos: KitchenInfo[]
  pickerInfo: PickerInfo
  driverInfo: DriverInfo
}

interface KitchensSummaryForTour {
  kitchensDeadline: number
  kitchensLatestTimeFinished: number
}

export interface PickerInfo {
  deadline: number
  finished: number
  completionState: CompletionState
}

export interface DriverInfo {
  deadline: number
  finished: number
  completionState: CompletionState
}

export interface PunctualityScoresForDay {
  kitchens: number
  pickers: number
  drivers: number
}

export type AggregatedPunctualityScores = PunctualityScoresForDay & {
  loading: boolean
}

export interface KitchenInfo {
  name: string
  finished: number
  completionState: CompletionState
}

// eslint-disable-next-line no-shadow
export enum CompletionState {
  WaitingForPreviousStep,
  InTime,
  JustInTime,
  OverDue,
  Inactive,
}
