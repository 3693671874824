import { GridCellParams, GridRowsProp } from '@mui/x-data-grid'
import { firestore } from '../../../logic/firebase'
import { CreateDataType } from './EmailTable.types'
import { COLLECTION_EMPLOYEES } from '../../../constants'
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'

export const updateIsSupervisorValue = async (
  params: GridCellParams,
  event:
    | React.MouseEvent<HTMLButtonElement, MouseEvent>
    | React.MouseEvent<Element, MouseEvent>,
  rows: GridRowsProp[],
  setRows: React.Dispatch<React.SetStateAction<(readonly GridRowsProp[])[]>>,
  createData: CreateDataType
): Promise<void> => {
  event.stopPropagation()
  event.preventDefault()
  const rowId = params.id
  const docString = rows[rowId].ref
  const docRef = doc(firestore, COLLECTION_EMPLOYEES, docString)
  setRows((rows) => {
    const tempRows = [...rows]
    tempRows[rowId].isSupervisor = !tempRows[rowId].isSupervisor
    return tempRows
  })
  await updateDoc(docRef, {
    isSupervisor: rows[rowId].isSupervisor,
  })
  getFireStoreData(setRows, createData)
}

export const getFireStoreData = async (
  setRows: React.Dispatch<React.SetStateAction<(readonly GridRowsProp[])[]>>,
  createData: CreateDataType
) => {
  const data = await getDocs(
    query(
      collection(firestore, COLLECTION_EMPLOYEES),
      where('isActive', '==', true)
    )
  )
  let rowsTemp = []
  data.docs.forEach((doc, index) => {
    const data = doc.data()
    const roles =
      data.roles === undefined ? '' : data.roles.join(', ').toString()
    const row = createData(
      index,
      data.email,
      data.id,
      data.firstName,
      data.lastName,
      data.isActive === true ? 'Aktiv' : 'Inaktiv',
      data.isSupervisor === true,
      roles,
      doc.ref.path
    )
    rowsTemp.push(row)
  })
  setRows(rowsTemp)
}
