/**
 * 0 = 0;
 * 1 = 2;
 * 2 = 4;
 * 3 = 6;
 * 4 = 16;
 * 5 = 24;
 * 6 = 40;
 * 7 = 48;
 * 8 = 64;
 */
export const spacing = [0, 2, 4, 6, 16, 24, 40, 48, 64]
