import React, { useEffect, useState } from 'react'
import { isToday } from '../../organisms/PublicDisplay/PublicDisplayFunctions'
import './BoldTextOrDate.css'

type BoldTextOrDate = {
  text: string
  date: Date
  showClockOnLiveView: boolean
  outerStyle?: React.CSSProperties
  innerStyle?: React.CSSProperties
}

export const BoldTextOrDate = (props: BoldTextOrDate) => {
  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [hours, setHours] = useState(0)

  useEffect(() => {
    const interval = setInterval(setTime, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  function setTime() {
    const currentDate = new Date()
    setSeconds(currentDate.getSeconds())
    setMinutes(currentDate.getMinutes())
    setHours(currentDate.getHours())
  }

  function timeDisplay(hours: number, minutes: number, seconds: number) {
    const h = hours.toString().length === 1 ? '0' + hours : hours
    const m = minutes.toString().length === 1 ? '0' + minutes : minutes
    const s = seconds.toString().length === 1 ? '0' + seconds : seconds
    return h + ':' + m + ':' + s
  }

  const showClock = props.showClockOnLiveView && isToday(props.date)

  return (
    <div style={props.outerStyle}>
      <div className="time" style={props.innerStyle}>
        {showClock ? timeDisplay(hours, minutes, seconds) : props.text}
      </div>
    </div>
  )
}
