import DefaultIcon from './../../../assets/tagIcons/default-icon.png'
import ProductionIcon from './../../../assets/tagIcons/production-icon.svg'
import BuyingIcon from './../../../assets/tagIcons/buying-icon.svg'
import CustomerServiceIcon from './../../../assets/tagIcons/customer-service-icon.svg'
import LogisticsIcon from './../../../assets/tagIcons/logistics-icon.svg'
import StockManagementIcon from './../../../assets/tagIcons/stock-management-icon.svg'
import PlanningIcon from './../../../assets/tagIcons/planning-icon.svg'

/**
 * Icon Assignments:
 * Tags according to Dani:
 * - Operations (Operations/Planning)
 * - Produktion (Production Küche)
 * - Einkauf (Buying)
 * - Kunden (Customer Service)
 * - Lager (Stockmanagement)
 * - Logistik (Logistics)
 */

export const getIconForTag = (tagName: string) => {
  switch (tagName) {
    case 'Operations':
      return PlanningIcon
    case 'Produktion':
      return ProductionIcon
    case 'Einkauf':
      return BuyingIcon
    case 'Kunden':
      return CustomerServiceIcon
    case 'Lager':
      return StockManagementIcon
    case 'Logistik':
      return LogisticsIcon
    default:
      return DefaultIcon
  }
}

export const firstName = (name: string) => {
  let parts = name.split(' ')
  if (parts.length > 0) {
    return parts[0]
  }
  return name
}
