import React from 'react'
import logo from '../../../../res/kk-logo@3x.png'
import { useNavigate} from 'react-router-dom'

type KKLogoProps = {}

export const KKLogo = (props: KKLogoProps) => {
  const navigate = useNavigate()

  return (
    <div className="switch">
      {/* KK Logo */}
      <img
        src={logo}
        className="pd-kk-logo"
        alt={logo}
        onClick={() => navigate('/')}
      />
    </div>
  )
}
