import React from 'react'
import { DeliveryDayCell } from '../../molecules/DeliveryDay/DeliveryDay'
import './DeliveryDayContainer.css'
import { useStateValue } from '../../../logic/state'

export const DeliveryDayContainer = () => {
  // @ts-ignore
  const [{ monday }] = useStateValue()
  const week = createWeekArray(monday)
  return (
    <div className="delivery-day-container">
      {week.map(day => (
        <DeliveryDayCell date={day} />
      ))}
    </div>
  )
}

const createWeekArray = (monday: Date) => {
  const result = []
  let iteratorDate = monday
  const dayInMs = 1000 * 60 * 60 * 24
  for (let i = 0; i < 5; i++) {
    result.push(iteratorDate)
    iteratorDate = new Date(iteratorDate.getTime() + dayInMs)
  }
  return result
}
