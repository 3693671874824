import React from 'react'
import './DateElement.css'
import { parseDate } from '../../../logic/util'

type DateProps = {
  date: Date
}
export const DateElement = (props: DateProps) => {
  return <div className="date-element">{parseDate(props.date)}</div>
}
