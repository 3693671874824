import React, { useState } from 'react'
import './Login.css'
import { Header } from '../../molecules/Header/Header'
import { TextField, Button } from '@mui/material'
import { auth } from '../../../logic/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'

export const Login = () => {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState<Error | null>(null)

  const emailInputChanged = (event: any) => {
    setEmail(event.target.value)
  }

  const passwordInputChanged = (event: any) => {
    setPassword(event.target.value)
  }

  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <div>
      <Header />
      <div className="loginContent">
        <h1 className="loginTitle">Login</h1>
        <TextField
          sx={{ marginTop: 20 }}
          variant="outlined"
          label={'Email'}
          value={email}
          onChange={emailInputChanged}
        />
        <TextField
          sx={{ marginTop: 20, marginBottom: 30 }}
          variant="outlined"
          label={error !== null ? 'Falsches Passwort' : 'Passwort'}
          value={password}
          onChange={passwordInputChanged}
          error={error !== null}
          type="password"
        />
        <Button
          disabled={email.length === 0 || password.length === 0}
          variant="contained"
          onClick={login}
        >
          Anmelden
        </Button>
      </div>
    </div>
  )
}
