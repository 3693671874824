import { COLLECTION_PRODUCTION_PLACES } from '../../../../constants'
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore'
import { KKElement } from './kkElement'

export class ProductionPlace extends KKElement {
  name: string
  sortId: string
  type: string
  collectionPlaceRef: DocumentReference

  constructor(snap: QueryDocumentSnapshot) {
    super(COLLECTION_PRODUCTION_PLACES)
    const data = snap.data()
    this.name = data.name
    this.sortId = data.sortId
    this.type = data.type
    this.collectionPlaceRef = data.collectionPlaceRef
    this.documentRef = snap.ref
  }
  getFlatData() {
    return {
      name: this.name,
      sortId: this.sortId,
      type: this.type,
      collectionPlaceRef: this.collectionPlaceRef,
      createdAt: new Date(),
      updatedAt: new Date(),
    }
  }
}

export const productionPlaceConverter = {
  toFirestore: (productionPlace: ProductionPlace) => ({}),
  fromFirestore: (snapshot, options) => {
    return new ProductionPlace(snapshot)
  },
}
