import {
  CompletionState,
  PunctualityScoresForDay,
  Tour,
} from '../../statisticsObject/StatisticObjectInterface'
import { SimpleMealComponentType } from '../../types'
import {
  ProductionPlace,
  ProductionPlaceAssignment,
} from './statistic-functions.types'

export const tenMinutesInSeconds: number = secondsFrom(0, 10)

// when you change this file, remember to change the same file inside the functions folder
export class StatisticFunctions {
  constructor(
    private bonusForGreen: number = 1,
    private bonusForYellow: number = 0.5,
    private pickerDeadlineLeadTime: number = secondsFrom(0, 15),
    private tourUrgentTimeIntervalDriver: number = secondsFrom(0, 2),
    private pickingTimeStatic: number = secondsFrom(0, 4),
    private pickingTimePerGroup: number = 45,
    private tourUrgentTimeIntervalPicker: number = secondsFrom(0, 2),
    private tourUrgentTimeIntervalKitchen: number = secondsFrom(0, 5)
  ) {}

  getPunctualityScoresForDay(tours: Tour[]): PunctualityScoresForDay {
    let bonusPickers = 0
    let bonusDrivers = 0
    let numberOfActiveTours = 0
    let bonusKitchens = 0
    let numberOfKitchens = 0
    for (const tour of tours) {
      if (
        !this.isTourInactive(
          tour.kitchensSummaryForTour.kitchensDeadline,
          this.getSecondsSinceMidnight()
        )
      ) {
        numberOfActiveTours += 1
        bonusPickers += this.calculateBonus(tour.pickerInfo.completionState)
        bonusDrivers += this.calculateBonus(tour.driverInfo.completionState)
        for (const kitchenInfo of tour.kitchenInfos) {
          if (kitchenInfo.completionState !== CompletionState.Inactive) {
            bonusKitchens += this.calculateBonus(kitchenInfo.completionState)
            numberOfKitchens += 1
          }
        }
      }
    }
    return {
      kitchens: this.calculatePercentage(bonusKitchens, numberOfKitchens),
      pickers: this.calculatePercentage(bonusPickers, numberOfActiveTours),
      drivers: this.calculatePercentage(bonusDrivers, numberOfActiveTours),
    }
  }

  calculateBonus(completionState: CompletionState): number {
    if (completionState === CompletionState.InTime) {
      return this.bonusForGreen
    }
    if (completionState === CompletionState.JustInTime) {
      return this.bonusForYellow
    }
    if (completionState === CompletionState.WaitingForPreviousStep) {
      return this.bonusForGreen
    }
    return 0
  }

  calculatePercentage(bonus: number, count: number): number {
    return count === 0 ? 0 : Math.round((bonus / count) * 100)
  }

  getUpdatedValueForTimeFinished(
    currentTime: number,
    finishedTime: number
  ): number {
    let result = currentTime
    if (finishedTime === undefined) return -1

    // is cooking unfinished
    if (finishedTime === -1) {
      result = -1
    }
    //cooking finished, get latest cooking finished time
    else {
      result = finishedTime > result ? finishedTime : result
    }
    return result
  }

  getCookingCompletedTimeForProductionPlace(
    productionPlaceName: string,
    productionPlaceRefPath: string,
    currentUsedProductionPlaces: Set<string>,
    groupDeliveryComponents: {
      mealComponentRefPath: string
      cookingCompleted: number
      isPartialCompleted: number | undefined
      id: string
    }[],
    productionPlaceAssignments: {
      mealComponentRefPath: string
      productionPlaceRefPath: string
    }[],
    mealComponentsUnfiltered: SimpleMealComponentType[]
  ): number {
    let cookingCompleted = -2
    if (
      currentUsedProductionPlaces.has(productionPlaceName) &&
      groupDeliveryComponents &&
      productionPlaceAssignments
    ) {
      for (const groupDeliveryComponent of groupDeliveryComponents) {
        for (const productionPlaceAssignment of productionPlaceAssignments) {
          if (
            groupDeliveryComponent.mealComponentRefPath ===
              productionPlaceAssignment.mealComponentRefPath &&
            productionPlaceAssignment.productionPlaceRefPath ===
              productionPlaceRefPath
          ) {
            const mealComponent = mealComponentsUnfiltered.find(
              mealComponentUnfiltered =>
                mealComponentUnfiltered.id ===
                groupDeliveryComponent.mealComponentRefPath
            )
            // component is partial
            if (mealComponent !== undefined && mealComponent.isPartial) {
              cookingCompleted = this.getUpdatedValueForTimeFinished(
                cookingCompleted,
                // written like this for jest
                groupDeliveryComponent.isPartialCompleted
                  ? groupDeliveryComponent.isPartialCompleted
                  : 0
              )
            }
            // component is not partial
            else {
              cookingCompleted = this.getUpdatedValueForTimeFinished(
                cookingCompleted,
                groupDeliveryComponent.cookingCompleted
              )
            }
            if (cookingCompleted < 0) return cookingCompleted
          }
        }
      }
    }
    return cookingCompleted
  }

  getKitchenDeadline(
    tourTime: number,
    groupDeliveriesForTourLength: number
  ): number {
    return (
      tourTime -
      this.pickerDeadlineLeadTime - // driver time
      (this.pickingTimeStatic + // picker time
        groupDeliveriesForTourLength * this.pickingTimePerGroup)
      //  fifteenMinutesInSeconds - // driver time
      //  (fourMinutesInSeconds + // picker time
      //    groupDeliveriesForTourLength * timeForGroupDelivery)
    )
  }

  getCompletionStateForKitchen(
    productionPlaceName: string,
    productionPlaceRefPath: string,
    tourTime: number,
    groupDeliveriesForTourLength: number,
    currentUsedProductionPlaces: Set<string>,
    productionPlaceAssignments: {
      mealComponentRefPath: string
      productionPlaceRefPath: string
    }[],
    groupDeliveryComponents: {
      mealComponentRefPath: string
      cookingCompleted: number
      isPartialCompleted: number | undefined
      id: string
    }[],
    secondsSinceMidnight: number,
    mealComponentsUnfiltered: SimpleMealComponentType[]
  ): {
    completionState: CompletionState
    finished: number
  } {
    const kitchenDeadline = this.getKitchenDeadline(
      tourTime,
      groupDeliveriesForTourLength
    )

    if (currentUsedProductionPlaces.has(productionPlaceName)) {
      const groupDeliveryComponentsTemp = groupDeliveryComponents.map(
        groupDeliveryComponent => {
          return {
            mealComponentRefPath: groupDeliveryComponent.mealComponentRefPath,
            cookingCompleted: groupDeliveryComponent.cookingCompleted,
            isPartialCompleted: groupDeliveryComponent.isPartialCompleted,
            id: groupDeliveryComponent.id,
          }
        }
      )
      const productionPlaceAssignmentsTemp = productionPlaceAssignments.map(
        productionPlaceAssignment => {
          return {
            mealComponentRefPath:
              productionPlaceAssignment.mealComponentRefPath,
            productionPlaceRefPath:
              productionPlaceAssignment.productionPlaceRefPath,
          }
        }
      )

      const cookingTimeFinished = this.getCookingCompletedTimeForProductionPlace(
        productionPlaceName,
        productionPlaceRefPath,
        currentUsedProductionPlaces,
        groupDeliveryComponentsTemp,
        productionPlaceAssignmentsTemp,
        mealComponentsUnfiltered
      )

      if (cookingTimeFinished >= 0) {
        // if kitchen finished cooking
        // and they finished intime (green)
        if (
          cookingTimeFinished <
          kitchenDeadline - this.tourUrgentTimeIntervalKitchen
        ) {
          // if (cookingTimeFinished < kitchenDeadline - fiveMinutesInSeconds) {
          return {
            completionState: CompletionState.InTime,
            finished: cookingTimeFinished,
          }
        }
        //  and they finished just before deadline (just in time)
        else if (cookingTimeFinished < kitchenDeadline) {
          return {
            completionState: CompletionState.JustInTime,
            finished: cookingTimeFinished,
          }
        }
        //  and they finished after the deadline (overdue)
        else if (
          cookingTimeFinished > kitchenDeadline ||
          kitchenDeadline < secondsSinceMidnight
        ) {
          return {
            completionState: CompletionState.OverDue,
            finished: cookingTimeFinished,
          }
        }
      } else {
        // kitchen cannot finish anymore before deadline
        if (kitchenDeadline < secondsSinceMidnight) {
          return {
            completionState: CompletionState.OverDue,
            finished: cookingTimeFinished,
          }
        }
        // kitchen can still finish before deadline therefor they are in idle state
        if (cookingTimeFinished === -1 || cookingTimeFinished === -2) {
          return {
            completionState: CompletionState.WaitingForPreviousStep,
            finished: cookingTimeFinished,
          }
        }
      }
    }
    // production place is not in production places for this tour
    else {
      // return CompletedState.Inactive
      return {
        completionState: CompletionState.Inactive,
        finished: -1,
      }
    }
    return {
      completionState: CompletionState.Inactive,
      finished: -1,
    }
  }

  getLastProductionPlaceTimeForTour(
    productionPlaceAssignmentsForTour: {
      productionPlaceRefId: string
      mealComponentRefId: string
    }[],
    productionPlaces: {
      refId: string
    }[],
    groupDeliveryComponentsForTour: {
      mealComponentRefId: string
      cookingCompleted: number
      isPartialCompleted: number
    }[],
    mealComponentsForTour: SimpleMealComponentType[]
  ): number {
    let lastProductionPlaceCompletionTime: number = -1
    const productionPlaceRefs = Array.from(
      new Set(
        productionPlaceAssignmentsForTour.map(
          assignment => assignment.productionPlaceRefId
        )
      )
    )
    for (const productionPlaceRef of productionPlaceRefs) {
      const productionPlace = productionPlaces.find(
        productionPl => productionPl.refId === productionPlaceRef
      )
      if (productionPlace !== undefined) {
        const assignments = productionPlaceAssignmentsForTour.filter(
          assignment =>
            assignment.productionPlaceRefId === productionPlace.refId
        )
        const mealComponentRefs = new Set(
          assignments.map(assignment => assignment.mealComponentRefId)
        )

        let groupDeliveryComponentsForProductionPlace: {
          mealComponentRefId: string
          cookingCompleted: number
          isPartialCompleted: number
        }[] = []
        mealComponentRefs.forEach(mealComponentRef => {
          const components = groupDeliveryComponentsForTour.filter(
            component => component.mealComponentRefId === mealComponentRef
          )
          groupDeliveryComponentsForProductionPlace = groupDeliveryComponentsForProductionPlace.concat(
            components
          )
        })

        let latestCompletionTime = -1
        for (const gdc of groupDeliveryComponentsForProductionPlace) {
          const mealComponent = mealComponentsForTour.filter(
            mealComponentForTour =>
              mealComponentForTour.id === gdc.mealComponentRefId
          )
          // is partial
          if (mealComponent.length === 1 && mealComponent[0].isPartial) {
            latestCompletionTime = this.getUpdatedValueForTimeFinished(
              latestCompletionTime,
              gdc.isPartialCompleted
            )
          }
          // is not partial
          else {
            latestCompletionTime = this.getUpdatedValueForTimeFinished(
              latestCompletionTime,
              gdc.cookingCompleted
            )
          }
          if (latestCompletionTime < 0) break
        }
        lastProductionPlaceCompletionTime = Math.max(
          lastProductionPlaceCompletionTime,
          latestCompletionTime
        )
      }
    }
    return lastProductionPlaceCompletionTime
  }

  getLastPickerCompletionTimeForTour(
    groupDeliveriesForTour: {
      pickingCompleted: number
    }[]
  ): number {
    let lastPickerCompletionTime: number = -1
    for (const groupDelivery of groupDeliveriesForTour) {
      if (groupDelivery.pickingCompleted > -1) {
        lastPickerCompletionTime = Math.max(
          lastPickerCompletionTime,
          groupDelivery.pickingCompleted
        )
      } else {
        lastPickerCompletionTime = -1
        break
      }
    }
    return lastPickerCompletionTime
  }

  getPickerDeadline(
    lastProductionPlaceCompletionTime: number,
    groupDeliveriesForTourLength: number,
    tourTime: number
  ): number {
    return Math.max(
      lastProductionPlaceCompletionTime +
        this.pickingTimeStatic +
        groupDeliveriesForTourLength * this.pickingTimePerGroup,
      tourTime - this.pickerDeadlineLeadTime
    )
  }

  getPickerCompletionState(
    productionPlaceAssignmentsForTour: {
      productionPlaceRefId: string
      mealComponentRefId: string
    }[],
    productionPlaces: { refId: string }[],
    groupDeliveryComponentsForTour: {
      mealComponentRefId: string
      cookingCompleted: number
      isPartialCompleted: number
    }[],
    groupDeliveriesForTour: { pickingCompleted: number }[],
    tourTime: number,
    secondsSinceMidnight: number,
    mealComponentsUnfiltered: SimpleMealComponentType[]
  ): { completedState: CompletionState; pickerFinishedTime: number } {
    let lastProductionPlaceCompletionTime: number = -1
    const productionPlaceAssignmentsForTourTemp = productionPlaceAssignmentsForTour.map(
      productionPlaceAssignmentForTour => {
        return {
          productionPlaceRefId:
            productionPlaceAssignmentForTour.productionPlaceRefId,
          mealComponentRefId:
            productionPlaceAssignmentForTour.mealComponentRefId,
        }
      }
    )
    const productionPlacesTemp = productionPlaces.map(productionPlace => {
      return { refId: productionPlace.refId }
    })

    const groupDeliveryComponentsForTourTemp = groupDeliveryComponentsForTour.map(
      groupDeliveryComponentForTour => {
        return {
          mealComponentRefId: groupDeliveryComponentForTour.mealComponentRefId,
          cookingCompleted: groupDeliveryComponentForTour.cookingCompleted,
          isPartialCompleted: groupDeliveryComponentForTour.isPartialCompleted,
        }
      }
    )

    lastProductionPlaceCompletionTime = this.getLastProductionPlaceTimeForTour(
      productionPlaceAssignmentsForTourTemp,
      productionPlacesTemp,
      groupDeliveryComponentsForTourTemp,
      mealComponentsUnfiltered
    )

    let lastPickerCompletionTime = -1
    lastPickerCompletionTime = this.getLastPickerCompletionTimeForTour(
      groupDeliveriesForTour.map(groupDeliveryForTour => {
        return {
          pickingCompleted: groupDeliveryForTour.pickingCompleted,
        }
      })
    )

    const pickerDeadline = this.getPickerDeadline(
      lastProductionPlaceCompletionTime,
      groupDeliveriesForTour.length,
      tourTime
    )
    // when picker is not ready and
    if (lastPickerCompletionTime < 0) {
      // cannot finish before deadline anymore
      if (
        secondsSinceMidnight > pickerDeadline &&
        lastProductionPlaceCompletionTime !== -1
      ) {
        return {
          completedState: CompletionState.OverDue,
          pickerFinishedTime: lastPickerCompletionTime,
        }
      }
      // can finish before deadline
      return {
        completedState: CompletionState.WaitingForPreviousStep,
        pickerFinishedTime: lastPickerCompletionTime,
      }
    }
    //  else when picker is ready
    else {
      // finished in time before deadline
      if (
        pickerDeadline - this.tourUrgentTimeIntervalPicker >
        lastPickerCompletionTime
      ) {
        return {
          completedState: CompletionState.InTime,
          pickerFinishedTime: lastPickerCompletionTime,
        }
      }
      // finished just in time before deadline
      else if (pickerDeadline > lastPickerCompletionTime) {
        return {
          completedState: CompletionState.JustInTime,
          pickerFinishedTime: lastPickerCompletionTime,
        }
      }
      // finished after deadline
      else {
        return {
          completedState: CompletionState.OverDue,
          pickerFinishedTime: lastPickerCompletionTime,
        }
      }
    }
  }

  getDriverDeadline(
    lastPickerCompletionTime: number,
    returnedValue: number,
    tourTime: number
  ): number {
    return Math.max(
      lastPickerCompletionTime + this.pickerDeadlineLeadTime,
      returnedValue + this.pickerDeadlineLeadTime,
      tourTime
    )
  }

  getReturnValueOfDriversTours(
    activeTourNumber: number,
    tourDriverRefPath: string | undefined,
    tours: {
      number: number
      driverRefPath: string | undefined
      returned: number | undefined
    }[]
  ): number {
    let returnedValue: number = -1
    if (tourDriverRefPath !== undefined && tours !== undefined) {
      tours.forEach(tourDiff => {
        if (
          tourDiff.driverRefPath &&
          tourDriverRefPath === tourDiff.driverRefPath &&
          activeTourNumber > tourDiff.number
        ) {
          returnedValue = Math.max(
            tourDiff.returned ? tourDiff.returned : -1,
            returnedValue
          )
        }
      })
    }
    return returnedValue
  }

  getDriverCompletionState(
    groupDeliveriesForTour: { pickingCompleted: number }[],
    tour: {
      number: number
      driverRefPath: string | undefined
      time: number
      readyToLeave: number
    },
    tours: {
      number: number
      driverRefPath: string | undefined
      returned: number | undefined
    }[],
    secondsSinceMidnight: number
  ): { completedState: CompletionState; driverFinished: number } {
    let lastPickerCompletionTime = -1
    lastPickerCompletionTime = this.getLastPickerCompletionTimeForTour(
      groupDeliveriesForTour.map(groupDeliveryForTour => {
        return {
          pickingCompleted: groupDeliveryForTour.pickingCompleted,
        }
      })
    )

    const returnedValue: number = this.getReturnValueOfDriversTours(
      tour.number,
      tour.driverRefPath,
      tours
    )

    const driverDeadline = this.getDriverDeadline(
      lastPickerCompletionTime,
      returnedValue,
      tour.time
    )

    // when tour was not leaving until now and
    if (tour.readyToLeave === -1) {
      // and driver cannot leave before deadline anymore
      if (
        secondsSinceMidnight > driverDeadline &&
        lastPickerCompletionTime !== -1
      ) {
        return {
          completedState: CompletionState.OverDue,
          driverFinished: tour.readyToLeave,
        }
      }
      // driver can still leave before deadline
      return {
        completedState: CompletionState.WaitingForPreviousStep,
        driverFinished: tour.readyToLeave,
      }
    }
    // tour left and
    else {
      // left in time before deadline
      if (
        tour.readyToLeave <
        driverDeadline - this.tourUrgentTimeIntervalDriver
      ) {
        return {
          completedState: CompletionState.InTime,
          driverFinished: tour.readyToLeave,
        }
      }
      // left shortly before deadline
      else if (tour.readyToLeave < driverDeadline) {
        return {
          completedState: CompletionState.JustInTime,
          driverFinished: tour.readyToLeave,
        }
      }
      // left after deadline
      else {
        return {
          completedState: CompletionState.OverDue,
          driverFinished: tour.readyToLeave,
        }
      }
    }
  }

  getProductionPlacesForTour(
    productionPlaceAssignmentsForTour: ProductionPlaceAssignment[],
    productionPlaces: ProductionPlace[]
  ): Set<string> {
    const productionPlacesForTour = new Set<string>()
    if (productionPlaceAssignmentsForTour) {
      productionPlaces.forEach(productionPlace => {
        productionPlaceAssignmentsForTour.forEach(productionPlaceAssignment => {
          if (
            productionPlaceAssignment.productionPlaceRef !== null &&
            productionPlace.ref.id ===
              productionPlaceAssignment.productionPlaceRef.id
          ) {
            productionPlacesForTour.add(productionPlace.name)
          }
        })
      })
    }
    return productionPlacesForTour
  }

  compare(a: ProductionPlace, b: ProductionPlace) {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  getSecondsSinceMidnight(): number {
    const now = new Date()
    return (
      // + 3600 because firebase uses UTC time and frontend uses central european time
      secondsFrom(now.getHours(), now.getMinutes()) + now.getSeconds() + 3600
    )
  }

  isTourInactive(
    kitchenDeadline: number,
    secondsSinceMidnight: number
  ): boolean {
    return kitchenDeadline - tenMinutesInSeconds > secondsSinceMidnight
  }
}

export function secondsFrom(hours: number, minutes: number): number {
  if (hours > 23 || hours < 0)
    throw new Error(`Unvalid input for hours: ${hours}`)
  if (minutes > 59 || minutes < 0)
    throw new Error(`Unvalid input for minutes: ${minutes}`)
  return hours * 3600 + minutes * 60
}
