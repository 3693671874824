import { DocumentReference, Timestamp } from 'firebase/firestore'
import { CompletionState } from './statisticsObject/StatisticObjectInterface'

export type Tour = {
  ref: DocumentReference
  driverRef?: DocumentReference
  createdAt: Timestamp
  updatedAt: Timestamp
  time: number
  number: number
  readyToLeave: number
  returned?: number
}

export type GroupDelivery = {
  ref: DocumentReference
  pickingCompleted: number
  createdAt: Timestamp
  updatedAt: Timestamp
  tourRef: DocumentReference
  groupRef: DocumentReference
  sortId: number
  deliveryDayRef: DocumentReference
  heatingType: number
}

export type MealComponent = {
  createdAt: Timestamp
  deliveryDayRef: DocumentReference
  name: string
  sortId: number
  totalWeight: number
  unit: string
  updatedAt: Timestamp
  isPartial?: boolean
}

export type SimpleMealComponentType = { id: string; isPartial: boolean }

export type ProductionPlaceAssignment = {
  totalWeight: number
  unit: string
  createdAt: Timestamp
  updatedAt: Timestamp
  tourRef: DocumentReference | null
  productionPlaceRef: DocumentReference | null
  mealComponentRef: DocumentReference | null
  deliveryDayRef: DocumentReference | null
}

export type ProductionPlace = {
  ref: DocumentReference
  sortId: number
  name: string
  createdAt: Timestamp
  updatedAt: Timestamp
  collectionPlaceRef: DocumentReference | null
}

export type GroupDeliveryComponent = {
  potCount: number
  weight: number
  unit: string | null
  cookingCompleted: number
  isCooking: number
  sortId: number | null
  isPartialCompleted: number | null
  createdAt: Timestamp
  updatedAt: Timestamp
  mealComponentRef: DocumentReference | null
  groupDeliveryRef: DocumentReference | null
  deliveryDayRef: DocumentReference | null
  tourRef: DocumentReference | null
}

export type DeliveryDay = {
  createdAt: Timestamp
  productionPlanImportSuccessful: boolean
  productionPlanImportLog: string
  filename: string
  date: string
  uploadProgress: number
  uploadState: 'uploading' | 'error' | 'success'
  pdfUrl: string | null
  warning: string | null
  xlsxUrl: string | null
}

type DeliveryDayLoadingStateLoading = {
  state: 'loading'
}
type DeliveryDayLoadingStateLoaded = {
  state: 'loaded'
  deliveryDay: DeliveryDay
}
type DeliveryDayLoadingStateError = {
  state: 'error'
  error: Error
}
type DeliveryDayLoadingStateNotAvailable = {
  state: 'not_available'
}
type DeliveryDayDeletingState = {
  state: 'deleting'
}

export type DeliveryDayLoadingState =
  | DeliveryDayLoadingStateLoading
  | DeliveryDayLoadingStateLoaded
  | DeliveryDayLoadingStateError
  | DeliveryDayLoadingStateNotAvailable
  | DeliveryDayDeletingState

export enum CompletedState {
  WaitingForPreviousStep = 'waiting for previous step',
  InTime = 'in time',
  JustInTime = 'just in time',
  OverDue = 'overdue',
  Inactive = 'inactive',
}

export type TourEntityStatus = {
  tourNumber: number
  tourTime: number
  kitchenDeadline: number
  kitchenFinished: number
  pickerDeadline: number
  pickerFinished: number
  driverDeadline: number
  driverFinished: number
  groupDeliveriesForTourLength: number
  type: string
  completedState: CompletedState
}

export enum DialogType {
  Edit = 'edit',
}

export type Info = {
  deadline: number
  finished: number
  completionState: CompletionState
}

export interface DayObject {
  year: number
  month: number
  day: number
}

export interface GlobalSettings {
  bonusForGreen: number
  bonusForYellow: number
  pickerDeadlineLeadTime: number
  tourUrgentTimeIntervalDriver: number
  pickingTimeStatic: number
  pickingTimePerGroup: number
  tourUrgentTimeIntervalPicker: number
  tourUrgentTimeIntervalKitchen: number
  errorDelay: number
  refreshIntervalCoreTasks: number
}
