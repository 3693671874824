import React from 'react'
import { twoDigitStringWith0 } from '../../molecules/HeaderPublicDisplay/HeaderPublicDisplayFunctions'
import { TourEntityStatus } from './../../../types'

export function secondsToHHMM(s) {
  if (s < 0) {
    return -1
  }
  let h = Math.floor(s / 3600).toString()
  let m = Math.floor((s % 3600) / 60).toString()
  let sec = (s - parseInt(h) * 3600 - parseInt(m) * 60).toString()

  h = twoDigitStringWith0(h)
  m = twoDigitStringWith0(m)
  sec = sec.slice(0, 2)
  sec = twoDigitStringWith0(sec)
  return h + ':' + m + ':' + sec
}
type TimeProps = { tourEntity: TourEntityStatus }
export const Time = (props: TimeProps) => {
  return (
    <div className="time">
      {secondsToHHMM(props.tourEntity ? props.tourEntity.tourTime : 0)}
    </div>
  )
}
