import React from 'react'
import trashData from '../../../res/trash-data.svg'
import './DeleteButton.css'

type DeleteButtonProps = {
  onPress: () => void
  blocked: boolean
}
export const DeleteButton = (props: DeleteButtonProps) => {
  return !props.blocked ? (
    <div className="delete-button" onClick={() => props.onPress()}>
      <img className={'.active'} src={trashData} alt={trashData} />
    </div>
  ) : null
}
