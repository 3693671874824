import { DayObject, Info } from '../../../types'
import {
  AggregatedPunctualityScores,
  CompletionState,
  DeliveryDayStatistic,
  KitchenInfo,
  PunctualityScoresForDay,
} from '../../../statisticsObject/StatisticObjectInterface'
import { QuerySnapshot } from 'firebase/firestore'

export function isToday(someDate: Date): boolean {
  const today = new Date()
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  )
}

export function getSecondsSinceMidnight(): number {
  const now = new Date()
  return secondsFrom(now.getHours(), now.getMinutes()) + now.getSeconds()
}

export function secondsFrom(hours: number, minutes: number): number {
  if (hours > 23 || hours < 0)
    throw new Error(`Unvalid input for hours: ${hours}`)
  if (minutes > 59 || minutes < 0)
    throw new Error(`Unvalid input for minutes: ${minutes}`)
  return hours * 3600 + minutes * 60
}

export function updateKitchenInfo(
  kitchenInfos: KitchenInfo[],
  kitchensDeadline: number,
  secondsSinceMidnight: number
): KitchenInfo[] {
  return kitchenInfos.map((kitchenInfo) => {
    const isKitchenOverdue =
      kitchenInfo.finished < 0 && kitchensDeadline < secondsSinceMidnight
    if (isKitchenOverdue) {
      kitchenInfo.completionState = CompletionState.OverDue
      return kitchenInfo
    } else {
      return kitchenInfo
    }
  })
}

export function updateInfos(
  info: Info,
  isWaitingForPreviousStep: boolean,
  secondsSinceMidnight: number
): Info {
  const isOverdue =
    !isWaitingForPreviousStep &&
    info.finished < 0 &&
    info.deadline < secondsSinceMidnight
  if (isOverdue) {
    info.completionState = CompletionState.OverDue
    return info
  } else {
    return info
  }
}

export function calcPercentage(
  sumValue: number,
  numberOfValues: number
): number {
  if (!(numberOfValues > 0)) {
    throw new Error('numberOfVlaues must be positive')
  }
  return Math.round(sumValue / numberOfValues)
}

export function dayObjectToString(dayObject: DayObject) {
  const twoDigetMonth =
    dayObject.month < 10 ? '0' + dayObject.month : dayObject.month
  const twoDigetDay = dayObject.day < 10 ? '0' + dayObject.day : dayObject.day
  return `${dayObject.year}-${twoDigetMonth}-${twoDigetDay}`
}

export function getDaysOfWeek(date: Date): DayObject[] {
  const copyDate = new Date(date.getTime())
  /*
   * currently full week.
   * comments are the way for only monday and sunday if needed
   */
  const isSunday = copyDate.getDay() === 0
  let first
  if (isSunday) {
    first = copyDate.getDate() - 6 // First day is the day of the month - the day of the week
  } else {
    first = copyDate.getDate() - (copyDate.getDay() - 1) // First day is the day of the month - the day of the week
  }

  const firstday = new Date(copyDate.setDate(first))
  // const lastday = new Date(date.setDate(firstday.getDate() + 6))
  // const days = [firstday, lastday]

  let days: Date[] = []
  for (let index = 0; index < 7; index++) {
    const tmpFirstday = new Date(firstday.getTime())
    const day = new Date(tmpFirstday.setDate(tmpFirstday.getDate() + index))
    days.push(day)
  }
  return days.map((day) => dateToDayObject(day))
}

function dateToDayObject(date: Date): DayObject {
  return {
    year: date.getUTCFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}

export function getAggregatedPunctualityScores(
  deliveryDayStatisticsSnapshot: QuerySnapshot<DeliveryDayStatistic>
): AggregatedPunctualityScores {
  const scores: PunctualityScoresForDay[] =
    deliveryDayStatisticsSnapshot.docs.map((doc) => {
      const deliveryDayStatistic: DeliveryDayStatistic = doc.data()
      return deliveryDayStatistic.punctualityScoresForDay
    })
  const result: AggregatedPunctualityScores = {
    kitchens: 0,
    pickers: 0,
    drivers: 0,
    loading: false,
  }
  for (const scoreOfDay of scores) {
    result.kitchens += scoreOfDay.kitchens
    result.pickers += scoreOfDay.pickers
    result.drivers += scoreOfDay.drivers
  }
  result.kitchens = calcPercentage(result.kitchens, scores.length)
  result.pickers = calcPercentage(result.pickers, scores.length)
  result.drivers = calcPercentage(result.drivers, scores.length)
  return result
}
