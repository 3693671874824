import React from 'react'
import { WeekPicker } from '../../molecules/WeekPicker/WeekPicker'
import './ControlPanel.css'
import { DeliveryDayContainer } from '../DeliveryDayContainer/DeliveryDayContainer'
import { Header } from '../../molecules/Header/Header'

export const ControlPanel = () => {
  // Title
  // Week Picker
  // List of DeliveryDays

  return (
    <div>
      <Header />
      <div className="control-panel">
        <h1 className="produktionsplanung">Produktionsplanung</h1>
        <WeekPicker />
        <DeliveryDayContainer />
      </div>
    </div>
  )
}
