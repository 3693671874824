export const images = {
  greenGateway: require('./../../../assets/wifiRouter-green.png'),
  redGateway: require('./../../../assets/wifiRouter-red.png'),
  greyGateway: require('./../../../assets/wifiRouter-grey.png'),
  greenSensor: require('./../../../assets/sensor-green.png'),
  redSensor: require('./../../../assets/sensor-red.png'),
  redBattery: require('./../../../assets/battery-icon.png'),
}

export type ImageTypes = keyof typeof images
