import React, { CSSProperties } from 'react'
import { ImageAsset } from '../../atoms/Image/Image'
import { ImageTypes } from '../../atoms/Image/Images'
import { getCelsiusFromFahrenheit } from '../../organisms/Sensorpush/SensorpushFunctions'
import './SensorpushSensor.css'

export interface SensorpushSensorProps {
  image: ImageTypes
  header: string
  temperatureNumber: number
  temperatureRangeMin: number
  temperatureRangeMax: number
  temperatureRangeEnabled: boolean
  time?: string
  hasRedBorder?: boolean
  isBatteryEmpty?: boolean
  style?: CSSProperties
}

export const SensorpushSensor = (props: SensorpushSensorProps) => {
  const {
    style,
    image,
    header,
    temperatureNumber,
    hasRedBorder,
    isBatteryEmpty,
    time,
    temperatureRangeMin,
    temperatureRangeMax,
    temperatureRangeEnabled,
  } = props

  // if temperature is out of scale color red and show predefined temperature range
  const temperatureIsOutOfScale =
    (Math.round(temperatureRangeMin) > Math.round(temperatureNumber) ||
      Math.round(temperatureNumber) > Math.round(temperatureRangeMax)) &&
    temperatureRangeEnabled

  // border needs to be red when battery is empty or temperature is out of scale
  const needsRedBorder =
    hasRedBorder || temperatureIsOutOfScale || isBatteryEmpty

  return (
    <div
      className={`box-root ${needsRedBorder ? 'box-red' : 'box-grey'}`}
      style={style}
    >
      <div className="innerBox">
        <ImageAsset
          image={image}
          style={{
            width: '2.6vh',
            height: '2.6vh',
          }}
        />
        <div className="text-header">{header}</div>
        {time && <div className="text-time">{time}</div>}
      </div>
      <div
        className="text-temperature-box"
        style={{
          marginLeft: isBatteryEmpty ? '0.73vw' : '2.8vw',
        }}
      >
        {isBatteryEmpty && (
          <ImageAsset
            image="redBattery"
            style={{
              width: '2.6vh',
              height: '2.6vh',
              marginRight: '0.54vw',
            }}
          />
        )}
        <div
          className="text-temperature-number"
          style={{
            color: temperatureIsOutOfScale
              ? 'rgb(214, 84, 73)'
              : 'rgb(74, 74, 74)',
          }}
        >
          {getCelsiusFromFahrenheit(temperatureNumber)}
        </div>
        <div
          className="text-temperature-unit"
          style={{
            color: temperatureIsOutOfScale
              ? 'rgb(214, 84, 73)'
              : 'rgb(74, 74, 74)',
          }}
        >
          °C
        </div>
        {temperatureIsOutOfScale && (
          <div className="temperature-range">{`${getCelsiusFromFahrenheit(
            temperatureRangeMin
          )} bis ${getCelsiusFromFahrenheit(temperatureRangeMax)}°C`}</div>
        )}
      </div>
    </div>
  )
}
