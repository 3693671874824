import React from 'react'

const blackPot = require('./../../../assets/pot-black.png')
const blackCart = require('./../../../assets/cart-black.png')
const blackTruck = require('./../../../assets/truck-black.png')

type HeaderPercentageIndicatorsProps = {
  iconName:
    | 'truck.png'
    | 'cart.png'
    | 'pot.png'
    | 'truck-black.png'
    | 'cart-black.png'
    | 'pot-black.png'
  percentage: number | string
  showEvaluation?: boolean
}
export const HeaderPercentageIndicators = (
  props: HeaderPercentageIndicatorsProps
) => {
  let icon = require('./../../../assets/' + props.iconName)
  const percentageForGreen = 94
  const percentageForYellow = 83
  var classNames = require('classnames')
  let classes
  if (props.showEvaluation === false || typeof props.percentage === 'string') {
    classes = classNames({
      'container-index': true,
      'container-neutral': true,
    })
    if (props.iconName === 'pot.png') {
      icon = blackPot
    }
    if (props.iconName === 'cart.png') {
      icon = blackCart
    }
    if (props.iconName === 'truck.png') {
      icon = blackTruck
    }
  } else {
    classes = classNames({
      'container-index': true,
      'container-green': percentageForGreen <= props.percentage,
      'container-red': props.percentage < percentageForYellow,
      'container-yellow':
        percentageForYellow <= props.percentage &&
        props.percentage < percentageForGreen,
    })
  }
  return (
    <div className={classes}>
      <img src={icon} alt={props.iconName} className="iconClass" />
      {`${props.percentage}%`}
    </div>
  )
}
